// Images
import _backgroundAboutDevices from '../assets/backgrounds/background-about-devices.png';
import _backgroundAboutTeam from '../assets/backgrounds/background-about-team.png';
import _backgroundHomeHeader from '../assets/backgrounds/background-home-header.png';
import _backgroundModelsSay from '../assets/backgrounds/background-models-say.png';
import _backgroundModelSecurity from '../assets/backgrounds/background-models-security.png';



import _deviceExampleModel from '../assets/images/device-example-model.png';
import _deviceExampleUser from '../assets/images/device-example-user.png';
import _devicesAbout from '../assets/images/devices-about.png';

// Icons
import _iconFaClub from '../assets/images/icon-fa-club.png';
import _iconFlexivelHour from '../assets/images/icon-flexivel-hour.png';
import _iconLearn from '../assets/images/icon-learn.png';
import _iconLive from '../assets/images/icon-live.png';
import _iconMonthlyPayment from '../assets/images/icon-monthly-payment.png';
import _iconRent from '../assets/images/icon-rent.png';
import _iconSuport24h from '../assets/images/icon-suport-24h.png';
import _iconTalks from '../assets/images/icon-talks.png';



// Images
import _mapLocalSecretio from '../assets/images/map-local-secretio.png';
import _registerRate from '../assets/images/register-rate.png';



// Logos
import _logoAbout from '../assets/logos/logo-about.png';
import _logoSecretioFull from '../assets/logos/logo-full.png';
import _logoIconApp from '../assets/logos/logo-icon.png';



// Persons
import _registerPeople from '../assets/persons/register-people.png';



export const BACKGROUNDS = {
  backgroundHomeHeader:             _backgroundHomeHeader,
  backgroundAboutDevices:           _backgroundAboutDevices,
  backgroundAboutTeam:              _backgroundAboutTeam,
  backgroundModelsSay:              _backgroundModelsSay,
  backgroundModelsSecurity:         _backgroundModelSecurity,
};


export const ICONS = {
  iconFaClub:                       _iconFaClub,
  iconLive:                         _iconLive,
  iconRent:                         _iconRent,

  iconFlexivelHour:                 _iconFlexivelHour,
  iconLearn:                        _iconLearn,
  iconMonthlyPayment:               _iconMonthlyPayment,
  iconSuport24h:                    _iconSuport24h,
  iconTalks:                        _iconTalks,
};


export const IMAGES = {
  deviceExampleModel:               _deviceExampleModel,
  deviceExampleUser:                _deviceExampleUser,
  devicesAbout:                     _devicesAbout,

  registerRate:                     _registerRate,
  mapLocalSecretio:                 _mapLocalSecretio,
};


export const LOGOS = {
  logoAbout:                        _logoAbout,
  logoIconApp:                      _logoIconApp,
  logoSecretioFull:                 _logoSecretioFull,
};


export const PERSONS = {
  registerPeople:                   _registerPeople,
};



const Images = {
  ...BACKGROUNDS,
  ...ICONS,
  ...IMAGES,
  ...LOGOS,
  ...PERSONS,
};



export default Images;
