import React from 'react';

import {
  Images,
  Sizes,
} from '../../../../../shared/constants';

import ButtonAction from '../../../../../components/Buttons/ButtonAction';

import {
  InfoHeaderContainer,
  InfoHeaderBackground,
  InfoHeaderContent,
  InfoHeaderRowView,
  InfoHeaderLeft,
  InfoHeaderLogo,
  InfoHeaderView,
  SectionInfoButtonView,
  InfoHeaderText,
  InfoHeaderDevicesImage,
  InfoHeaderPromotionText,
} from './styled';



const SectionInfoHeader: React.FC = () => {
  return (

    <InfoHeaderContainer>

      <InfoHeaderBackground
        src={Images.backgroundAboutDevices}
      />



      <InfoHeaderContent>

        <InfoHeaderRowView>

          <InfoHeaderLeft>
            <InfoHeaderLogo
              src={Images.logoAbout}
            />

            <InfoHeaderView>
              <InfoHeaderText>
                Experimente as melhores ferramentas que estão disponíveis.
              </InfoHeaderText>

              <InfoHeaderText>
                Buscas com um clique, design avançado, experiência Streamate e a maior conversão com as melhores opções de pagamento.
              </InfoHeaderText>
            </InfoHeaderView>

            <SectionInfoButtonView>
              <ButtonAction
                text={'Seja um modelo Secretio'}
                height={Sizes.landingButtonHeight}
              />
            </SectionInfoButtonView>
          </InfoHeaderLeft>

          <InfoHeaderDevicesImage
            src={Images.devicesAbout}
          />
        </InfoHeaderRowView>

        <InfoHeaderPromotionText>
          35% PARA A VIDA TODA | PAGAMENTO POR ASSINATURA | PAGAMENTO POR GASTADOR
        </InfoHeaderPromotionText>

      </InfoHeaderContent>

    </InfoHeaderContainer>


  );
};



export default SectionInfoHeader;
