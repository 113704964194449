import {
  EEnvsName,
} from '../../enums';

import DEV_VARS from './var.dev';
import LOCAL_VARS from './var.local';
import PROD_VARS from './var.prod';



const Vars = (varReceived?: EEnvsName) => {
  const env = varReceived || process.env.REACT_APP_NODE_ENV;

  switch (env) {
    case EEnvsName.LOCAL: return LOCAL_VARS;
    case EEnvsName.DEV: return DEV_VARS;
    case EEnvsName.PROD: return PROD_VARS;
    default: return LOCAL_VARS;
  }
};



export default Vars;
