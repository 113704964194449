import React from 'react';

import {
  CardPlanPricesView,
  CardPlanPricesDiscountView,
  CardPlanPricesDiscountOld,
  CardPlanPricesDiscountPercent,
  CardPlanPricesValue,
  CardPlanPricesPeriod,
} from './styled';



export interface IProps {
  value?: string;
  period?: string;
}



const CardPrice: React.FC<IProps> = (props) => {
  return (

    <CardPlanPricesView>

      {false && (
        <CardPlanPricesDiscountView>
          <CardPlanPricesDiscountOld>
            - R$ 46
          </CardPlanPricesDiscountOld>


          <CardPlanPricesDiscountPercent>
            - 35%
          </CardPlanPricesDiscountPercent>
        </CardPlanPricesDiscountView>
      )}


      <CardPlanPricesValue>
        {props.value}
      </CardPlanPricesValue>


      <CardPlanPricesPeriod>
        {props.period}
      </CardPlanPricesPeriod>

    </CardPlanPricesView>

  );
};



export default CardPrice;
