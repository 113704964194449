import styled from 'styled-components';

import {
  SectionTitle,
} from '../../../../styles/styled.layout';



export const PricesUserGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  padding-bottom: 70px;
`;

export const SectionPlanUserTitle = styled(SectionTitle)`
  margin-bottom: 38px;
`;


export const PricesUserGridWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: 100%;
  max-width: 1180px;
`;
