import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import CardTip from '../../../components/Cards/CardTip';

import {
  Screen,
} from '../../../components/Composh/web';

import {
  TipsHeaderContainer,
  TipsHeaderBackground,
  TipsHeaderContent,
  TipsHeaderInfoView,
  TipsHeaderTitle,
  TipsHeaderText,
} from './styled';



const TipsCustomerScreen: React.FC = () => {
  //   RESPEITO MÚTUO
  // Esse negócio às vezes pode ser difícil.Nunca se esqueça que você está lidando com outro ser humano.Sempre dê o respeito que espera receber.

  // TENHA CAUTELA!
  // Especialmente com perfis novos.
  // Fique de olho no status "conhecido" que aparece em todo perfil.
  //   Confira as avaliações.
  // Você sempre pode bloquear quem lhe incomoda.


  // PROTEJA SEU PERFIL E SUA PRIVACIDADE
  // Sempre tenha cuidado ao compartilhar informações pessoais com os outros, seja em conversas ou no seu perfil.
  // Nunca mande dinheiro ou informações do cartão de crédito para pessoas que você conheceu na internet ou que não conhece muito bem.
  // Nunca informe sua senha.Não deixe de guardar suas informações de acesso em lugar seguro.


  // ANTES DO ENCONTRO...
  // Em dúvida ? Fique à vontade para perguntar ou pedir uma chamada por vídeo.
  // Diga a ele que o encontrou por meio de HUNQZ.com.
  // Deixe claro o preço e os serviços a serem prestados.
  // Para o primeiro encontro, marque em local público ou diga a um amigo.
  // Esteja preparado, ou seja, com camisinhas.

  // DURANTE O ENCONTRO
  // O que você faz ou deixa de fazer é decisão particular sua.Leve isso a sério e não abra mão disso, mesmo se no calor do momento você for tentado por outros.

  // Não se sinta forçado a fazer nada que lhe deixe desconfortável.
  // Tenha coragem e retire - se na hora que quiser.
  // Não deixe sua bebida sem supervisão.
  // Se você receber, não deixe nada de valor por perto.
  // Cuide da sua saúde, é a sua vida.Ninguém pode garantir que ele não tem alguma DST.


  // DEPOIS DO ENCONTRO
  // Esperamos que você tenha tido uma aventura excitante! Não se esqueça de escrever uma avaliação e salve o HUNQ como "conhecido" para aumentar sua credibilidade.
  // Caso haja problemas, não hesite em entrar em contato conosco e com as autoridades locais.


  function renderTipExample() {
    return (

      <CardTip
      />

    );
  };



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <TipsHeaderContainer>

        <TipsHeaderBackground
          alt={''}
        />

        <TipsHeaderContent>
          <TipsHeaderInfoView>
            <TipsHeaderTitle>
              Dicas para clientes
            </TipsHeaderTitle>

            <TipsHeaderText>
              Para ter prazer máximo
            </TipsHeaderText>
          </TipsHeaderInfoView>
        </TipsHeaderContent>

      </TipsHeaderContainer>



      <div>
        {renderTipExample()}
      </div>

    </Screen>

  );
};



export default TipsCustomerScreen;
