import React from 'react';

import {
  Images,
} from '../../../../../shared/constants';

import {
  FeedbackContainer,
  FeedbackBackground,
  FeedbackContent,
  FeedbackSectionContent,
  FeedbackSectionTitle,
  FeedbackSectionColorTitle,
  FeedbackSectionText,
  FeedbackSectionModelsView,
  FeedbackSectionItemView,
  FeedbackSectionItemImage,
  FeedbackSectionItemName,
  FeedbackSectionItemUserName,
  FeedbackSectionItemText,
} from './styled';



const SectionFeedbackModel: React.FC = () => {
  function renderFeedbackModel(image: string, title: string, username: string, text: string) {
    return (

      <FeedbackSectionItemView>

        <FeedbackSectionItemImage
          src={image}
        />

        <FeedbackSectionItemName>
          {title}
        </FeedbackSectionItemName>

        <FeedbackSectionItemUserName>
          {username}
        </FeedbackSectionItemUserName>

        <FeedbackSectionItemText>
          &quot;
          {text}
          &quot;
        </FeedbackSectionItemText>

      </FeedbackSectionItemView>

    );
  }



  return (

    <FeedbackContainer>

      <FeedbackBackground
        src={Images.backgroundModelsSay}
      />

      <FeedbackContent>
        <FeedbackSectionContent>

          <FeedbackSectionTitle>
            O que <FeedbackSectionColorTitle>Dizem os modelos?</FeedbackSectionColorTitle>
          </FeedbackSectionTitle>


          <FeedbackSectionText>
            Estamos no mercado desde 1996 e estamos sediados nos Estados Unidos. Você pode se sentir confortável sabendo que está trabalhando com uma empresa que lidera o mundo do chat ao vivo há mais de 20 anos!
          </FeedbackSectionText>


          <FeedbackSectionModelsView>

            {renderFeedbackModel(
              '/images/skins/webcam4money/img/models/katharine.png',
              'Katharine Cane',
              '@username',
              'Sou meu próprio patrão, mas tenho uma equipe inteira com a qual posso contar para qualquer coisa.',
            )}

            {renderFeedbackModel(
              '/images/skins/webcam4money/img/models/katharine.png',
              'Lindsey Banks',
              '@username',
              'I love webcam modeling. I love the convenience of it!',
            )}

            {renderFeedbackModel(
              '/images/skins/webcam4money/img/models/katharine.png',
              'Izzi',
              '@username',
              'I love all my fans for giving me the life I`ve always dreamed of.',
            )}

            {renderFeedbackModel(
              '/images/skins/webcam4money/img/models/katharine.png',
              'Blake Summers',
              '@username',
              'The thing I love most is the freedom, and how fun camming is.',
            )}

          </FeedbackSectionModelsView>

        </FeedbackSectionContent>
      </FeedbackContent>

    </FeedbackContainer>


  );
};



export default SectionFeedbackModel;
