import React from 'react';

import {
  AboutCompanyHQContent,
  AboutCompanyHQView,
  AboutCompanyHQInfoView,
  AboutCompanyHQTitle,
  AboutCompanyHQViewStreet,
  AboutCompanyHQStreet,
  AboutCompanyHQViewNumber,
} from './styled';



const SectionLocals: React.FC = () => {
  return (

    <AboutCompanyHQContent>
      <AboutCompanyHQView>
        <AboutCompanyHQInfoView>

          <AboutCompanyHQTitle>
            Secretio BV
          </AboutCompanyHQTitle>


          <AboutCompanyHQViewStreet>
            <AboutCompanyHQStreet>
              De Ruyterkade 7
            </AboutCompanyHQStreet>

            <AboutCompanyHQStreet>
              1013 AA Amsterdam
            </AboutCompanyHQStreet>

            <AboutCompanyHQStreet>
              Netherlands
            </AboutCompanyHQStreet>
          </AboutCompanyHQViewStreet>


          <AboutCompanyHQViewNumber>
            <AboutCompanyHQStreet>
              Fax: +31 20 5640451
            </AboutCompanyHQStreet>

            <AboutCompanyHQStreet>
              Chamber of Commerce (Amsterdam):  Nr. 70533520
            </AboutCompanyHQStreet>

            <AboutCompanyHQStreet>
              VAT-ID:  NL 8583.62.557B01
            </AboutCompanyHQStreet>
          </AboutCompanyHQViewNumber>

        </AboutCompanyHQInfoView>
      </AboutCompanyHQView>
    </AboutCompanyHQContent>

  );
};



export default SectionLocals;
