import React,
{
  useEffect,
} from 'react';
import './App.css';

// import {
//   Provider,
// } from 'react-redux';

// import {
//   persistStore,
// } from 'redux-persist';

// import {
//   PersistGate,
// } from 'redux-persist/integration/react';

import * as PackageInfo from '../package.json';

import BadgeEnv from './components/Badges/BadgeEnv';

import Navigation from './navigation/routes/app.routes';

// import {
//   storePersisted,
// } from './redux';


// const persistor = persistStore(storePersisted);



const App: React.FC = () => {
  const projectPackage = PackageInfo;

  useEffect(() => {
    console.log(`Secretio Website Version: ${projectPackage.version}`);
  }, []);



  return (

    // <Provider store={storePersisted}>
    // <PersistGate loading={null} persistor={persistor}>
    <>

      <BadgeEnv />

      <Navigation />

    </>
    // </PersistGate>
    // </Provider>

  );
};



export default App;
