import React from 'react';

// import { Container } from './styles';

const Error404Screen: React.FC = () => {
  return (

    <h2>
      Página não encontrada
    </h2>

  );
};

export default Error404Screen;
