import styled from 'styled-components';

import {
  Colors,
} from '../../../../shared/constants';



export const AboutCompanyMapView = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 325px;
  min-height: 325px;
  max-height: 325px;
  background-color: #010c13;
`;

export const AboutCompanyMapModule = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: #c10320;
  width: 50%;
`;

export const AboutCompanyMapBookNow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 640px;
  padding: 32px;
  padding-right: 4rem;

  :after {
    border-top-width: 15rem;
    border-bottom-width: 15rem;
    border-bottom: 10rem solid #010c13;
    border-left: 4rem solid #c10320;
    border-top: 10rem solid #010c13;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;

export const AboutCompanyMapText = styled.p`
  padding-bottom: 8px;
  color: ${Colors.white};
  text-align: center;
  text-shadow: 1px 1px 0 rgba(0,0,0,.5);
  font-size: 1.5rem;
`;

export const AboutCompanyMapImageContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 50%;
`;

export const AboutCompanyMapImageView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 640px;
  padding-top: 36px;
  padding-left: 74px;
  padding-right: 74px;
  padding-bottom: 36px;
`;

export const AboutCompanyMap = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
