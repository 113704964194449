// User Plans

export const PremiumPlan = [
  'Você obtém todos os recursos básicos do plano Grátis.',
  'Fotos e vídeos exclusivos',
  'Escrever reviews',
  'Entre em contato direto pela plataforma do Secretio.',
  'Seja um cliente Secretio especial e verificado.',
  'Fotos e vídeos exclusivos',
  'Ler e escrever reviews',
  'Todos os filtros disponíveis.',
  'Saiba os novos anúncios',
];



export const FreePlan = [
  'O seu perfil é seguro e sigiloso',
  'Todas os Detalhes e Características disponíveis.',
  'Acompanhantes com documentos verificados',
  'Acompanhantes com mídia de comparação aprovados e atualizados periodicamente',
  'Visualize instantaneamente a galeria de fotos de acompanhantes.',
];



// Modele Plans

export const ModeleGoldPlan = [
  {
    title: 'Todas as funcionalidades SILVER e BRONZE',
    description: 'Você obtém todos os recursos ouro do plano SILVER e BRONZE além de todos os recursos extras.',
  },
  {
    title: 'No topo de todos os resultados de pesquisa',
    description: 'Primeira colocação do seu anúncio Secretio em todas as pesquisas.',
  },
];



export const ModeleSilverPlan = [
  {
    title: 'Todas as funcionalidades básicas',
    description: 'Você obtém todos os recursos básicos do plano BRONZE além de todos os recursos extras.',
  },
  {
    title: 'No topo de todos os resultados de pesquisa',
    description: 'Primeira colocação do seu anúncio Secretio em todas as pesquisas.', // Seu anúncio em banners exclusivos dentro do perfil da concorrência
  },
  {
    title: 'Colocação em pesquisas',
    description: 'Os perfis de ouro alternam aleatoriamente na página inicial de suas localizações atuais e de viagens.',
  },
];



export const ModeleBronzePlan = [
  {
    title: 'Colocação em pesquisas',
    description: 'Seu anúncio Secretio será exibido nos resultados de pesquisas para as determinadas localizações e explorações.',
  },
  {
    title: 'Três lugares de viagem incluída',
    description: 'Você pode usar até três localizações de viagem para anunciar onde você está.',
  },
  {
    title: 'Fotos e Vídeos Ilimitados',
    description: 'Você pode carregar quantas fotos ou vídeos desejar',
  },
  {
    title: 'Link Pessoal',
    description: 'Você recebe um link com seu nome de usuário direto para o seu perfil.',
  },
];



export const ModeleFreePlan = [
  'Acesso a ferramenta como viagens, controle de galeria e muito mais.',
];
