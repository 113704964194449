import React from 'react';

import {
  Videos,
} from '../../../../../shared/constants';

import {
  BeModelRegisterStartContent,
  BeModelRegisterStartInfo,
  BeModelSeeHowContent,
  BeModelSeeHowTitle,
  BeModelSeeHowName,
  BeModelSeeHowDescription,
  BeModelSeeHowUnderlineDescription,
  BeModelSeeHowVideoView,
} from './styled';



const SectionWhy: React.FC = () => {
  return (

    <BeModelSeeHowContent>
      <BeModelRegisterStartContent>

        <BeModelRegisterStartInfo>
          <BeModelSeeHowTitle>
            Seja
          </BeModelSeeHowTitle>

          <BeModelSeeHowName>
            Secretio
          </BeModelSeeHowName>

          <BeModelSeeHowDescription>
            Os melhores profissionais da área
            &nbsp;
            <BeModelSeeHowUnderlineDescription>
              escolhem o Secretio
            </BeModelSeeHowUnderlineDescription>
            .
          </BeModelSeeHowDescription>
        </BeModelRegisterStartInfo>


        <BeModelSeeHowVideoView id={'SecretioWhy'} controls>
          <source
            type={'video/mp4'}
            src={Videos.registerHow}
          />
        </BeModelSeeHowVideoView>

      </BeModelRegisterStartContent>
    </BeModelSeeHowContent>

  );
};



export default SectionWhy;
