import React from 'react';

import {
  Colors,
  Images,
} from '../../shared/constants';

import {
  Content,
  Screen,
} from '../../components/Composh/web';

import FooterApp from '../../components/Footer';
import Header from '../../components/Header';
import SectionAbout from '../../components/Sections/SectionAbout';
import TreeScreens from '../../components/TreeScreens';

import SectionLocals from './Sections/SectionLocals';
import SectionMap from './Sections/SectionMap';
import SectionTopModel from './Sections/SectionTopModel';



const AboutCompanyScreen: React.FC = () => {
  return (

    <Screen
      backgroundColor={Colors.appBackground}>
      <Content>

        <Header />



        <SectionTopModel />

        <SectionAbout
          backgroundImage={Images.backgroundAboutTeam}
          title={'Reserve sua fantasia'}
          subTitle={'Fácil, gratuito e mundial'}
          text={'Com diversos modelos registrados em todo o mundo, somos a maior rede de modelos adultos para serviços de companhia, cameras ao vivo e rent. Facilitamos o contato e ajudamos você a transformar suas fantasias em realidade.'}
        />

        <SectionMap />

        <SectionLocals />



        <TreeScreens />

        <FooterApp />

      </Content>
    </Screen>

  );
};



export default AboutCompanyScreen;
