export const FONTS = {
  input:                            16,
  regular:                          14,
  medium:                           12,
  small:                            11,
  tiny:                             10,
};


export const BUTTONS = {
  buttonHeight:                     37,
  landingButtonHeight:              45,
  buttonRadius:                     4,
};


export const CONTAINERS = {
  landingMaxWidth:                  1320,
};


export const CARD = {
  cardRadius:                       6,
  cardHomeMaxWidth:                 795,
};



export const ICONS = {
  iconSize:                         35,
  iconInputSize:                    27,
  iconAndroidSize:                  24,
  iconIosSize:                      22,
};



export const MARGINS = {
  contentMaxWidth:                  1450,
  messageMaxWidth:                  1600,
  paddingLanding:                 9,

  contentLeftWidth:                 250,
  contentRightHomeWidth:            365,
  contentRightProfileWidth:         265,
  contentMargin:                    20,

  contentRentWidth:                 470,
  paddingContainer:                 16,


  menuContent:                      35,
};


export const TOGGLE = {
  TOOGLE_HEIGHT:                    40, // 42,
};



const Sizes = {
  ...BUTTONS,
  ...CONTAINERS,
  ...CARD,
  ...ICONS,
  ...MARGINS,
  ...TOGGLE,
};



export default Sizes;
