import styled from 'styled-components';



export const ContactSectionContainer = styled.div`
-webkit-text-size-adjust: 100%;
font-size: 1rem;
line-height: 1.6;
font-family: 'Open Sans',sans-serif;
color: #111111;
box-sizing: border-box;
display: block;
position: relative;
`;

export const ContactSectionContent = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 1140px;
  min-height: 664px;
  margin: 0 auto;
`;

export const ContactSectionFormView = styled.div`
position: relative;
display: flex;
flex-direction: column;
width: 570px;
min-height: 664px;
background-color: #0c2d37;
`;

export const ContactSectionInfoView = styled.div`
position: relative;
display: flex;
flex-direction: column;
width: 570px;
`;

export const ContactSectionInfoImage = styled.img`
object-fit: cover;
position: relative;
display: flex;
flex: 1;
height: 344px;
background-color: darkred;
`;

export const ContactSectionInfoItemsView = styled.div`
max-width: 100%;
position: relative;
display: flex;
background-clip: padding-box;
background-origin: padding-box;
text-align: center;
opacity: 1;
height: 339px;
background-color: #63b9d2;
flex: 1;
`;
