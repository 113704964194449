import React from 'react';

import ButtonAction from '../../../../components/Buttons/ButtonAction';

import {
  SectionHeaderModelView,
  SectionHeaderBackground,
  SectionHeaderInfoContent,
  SectionHeaderInfoView,
  SectionHeaderTitle,
  SectionHeaderDescription,
  SectionHeaderButtonView,
} from './styled';



const SectionTopModel: React.FC = () => {
  return (

    <SectionHeaderModelView>

      <SectionHeaderBackground
      />


      <SectionHeaderInfoContent>

        <SectionHeaderInfoView>
          <SectionHeaderTitle>
            Créditos de Aposta até R$200
          </SectionHeaderTitle>

          <SectionHeaderDescription>
            Para Novos Clientes
          </SectionHeaderDescription>

          <SectionHeaderButtonView>
            <ButtonAction
              text={'Acesse o Secretio'}
            />
          </SectionHeaderButtonView>
        </SectionHeaderInfoView>

      </SectionHeaderInfoContent>

    </SectionHeaderModelView>

  );
};



export default SectionTopModel;
