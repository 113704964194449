import React from 'react';

import {
  useHistory,
} from 'react-router-dom';

import NameRoutes from '../../navigation/names';

import {
  Images,
} from '../../shared/constants';

import Vars from '../../shared/environments/variables';

import {
  TreeScreensContainer,
  TreeScreensFooterMain,
  TreeScreensImageView,
  TreeScreensImage,
  TreeHandwriting,
  TreeLinksContainer,
  TreeLinksContent,
  TreeLinksItemView,
  TreeLinkViewItem,
  TreeLinksTitle,
  TreeLinksButton,
  TreeLinksText,
  TreeSupportText,
} from './styled';



const TreeScreens: React.FC = () => {
  const history = useHistory();

  return (

    <TreeScreensContainer>

      <TreeScreensFooterMain>
        <TreeScreensImageView>
          <TreeScreensImage
            src={Images.logoSecretioFull}
          />
        </TreeScreensImageView>

        <TreeHandwriting>
          Seu segredo está a salvo
        </TreeHandwriting>
      </TreeScreensFooterMain>


      <TreeLinksContainer>
        <TreeLinksContent>

          <TreeLinksItemView>
            <TreeLinkViewItem>
              <TreeLinksTitle>
                {'Secretio'.toUpperCase()}
              </TreeLinksTitle>

              <TreeLinksButton
                onClick={() => {
                  history.push(NameRoutes.LandingHomeScreen);
                }}>
                <TreeLinksText>
                  Secretio
                </TreeLinksText>
              </TreeLinksButton>

              <TreeLinksButton
                onClick={() => {
                  history.push(NameRoutes.PricesScreen);
                }}>
                <TreeLinksText>
                  Preços
                </TreeLinksText>
              </TreeLinksButton>

              <TreeLinksButton
                onClick={() => {
                  history.push(NameRoutes.AboutCompanyScreen);
                }}>
                <TreeLinksText>
                  Quem somos
                </TreeLinksText>
              </TreeLinksButton>

              <TreeLinksButton
                target={'_blank'}
                onClick={() => {
                  history.push(Vars().secretioRankingApp);
                }}>
                <TreeLinksText>
                  Rankings
                </TreeLinksText>
              </TreeLinksButton>

              <TreeLinksButton
                onClick={() => {
                  history.push(NameRoutes.BeModeleScreen);
                }}>
                <TreeLinksText>
                  Seja um modelo
                </TreeLinksText>
              </TreeLinksButton>

              <TreeLinksButton
                target={'_blank'}
                onClick={() => {
                  history.push(NameRoutes.BlogScreen);
                }}>
                <TreeLinksText>
                  Blog
                </TreeLinksText>
              </TreeLinksButton>
            </TreeLinkViewItem>
          </TreeLinksItemView>


          <TreeLinksItemView>
            <TreeLinkViewItem>
              <TreeLinksTitle>
                {'Acessar'.toUpperCase()}
              </TreeLinksTitle>

              <TreeLinksButton
                target={'_blank'}
                onClick={() => {
                  history.push(Vars().secretioApp);
                }}>
                <TreeLinksText>
                  Secretio App
                </TreeLinksText>
              </TreeLinksButton>
            </TreeLinkViewItem>


            <TreeLinkViewItem>
              <TreeLinksTitle>
                {'Conteúdo'.toUpperCase()}
              </TreeLinksTitle>

              <TreeLinksButton
                onClick={() => {
                  history.push(NameRoutes.ChooseCountryScreen);
                }}>
                <TreeLinksText>
                  Todo o Secretio
                </TreeLinksText>
              </TreeLinksButton>
            </TreeLinkViewItem>
          </TreeLinksItemView>


          <TreeLinksItemView>
            <TreeLinkViewItem>
              <TreeLinksTitle>
                {'Políticas'.toUpperCase()}
              </TreeLinksTitle>

              <TreeLinksButton
                target={'_blank'}
                onClick={() => {
                  history.push(NameRoutes.TermUseScreen);
                }}>
                <TreeLinksText>
                  Termos de Uso
                </TreeLinksText>
              </TreeLinksButton>

              <TreeLinksButton
                target={'_blank'}
                onClick={() => {
                  history.push(NameRoutes.TermPrivacyScreen);
                }}>
                <TreeLinksText>
                  Política de Privacidade
                </TreeLinksText>
              </TreeLinksButton>

              <TreeLinksButton
                target={'_blank'}
                onClick={() => {
                  history.push(NameRoutes.TermCookies);
                }}>
                <TreeLinksText>
                  Política de Cookies
                </TreeLinksText>
              </TreeLinksButton>
            </TreeLinkViewItem>
          </TreeLinksItemView>


          <TreeLinksItemView>
            <TreeLinkViewItem>
              <TreeLinksTitle>
                {'Fale Conosco'.toUpperCase()}
              </TreeLinksTitle>

              <TreeLinksButton
                target={'_blank'}
                onClick={() => {
                  history.push(NameRoutes.ContactScreen);
                }}>
                <TreeLinksText>
                  Contato
                </TreeLinksText>
              </TreeLinksButton>

              <TreeLinksButton
                target={'_blank'}
                onClick={() => {
                  history.push(NameRoutes.HelpScreen);
                }}>
                <TreeLinksText>
                  Ajuda
                </TreeLinksText>
              </TreeLinksButton>
            </TreeLinkViewItem>
          </TreeLinksItemView>


          <TreeLinksItemView>
            <TreeLinksButton
              target={'_blank'}
              onClick={() => {
                // history.push(pathname);
              }}>
              <TreeSupportText>
                Conta global
              </TreeSupportText>
            </TreeLinksButton>
          </TreeLinksItemView>

        </TreeLinksContent >
      </TreeLinksContainer >

    </TreeScreensContainer >

  );
};



export default TreeScreens;
