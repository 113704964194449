import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';



export const SectionItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 440px;
  min-height: 440px;
  background-color: #ad7900;
`;

export const SectionItemsView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: ${Sizes.landingMaxWidth}px;
  height: 100%;
`;

export const SectionItemsRowView = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 45px;
  gap: 80px;
`;

export const SectionViewItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  border-radius: 5px;

  :hover {
    background-color: ${Colors.black};
  }
`;

export const SectionItemImage = styled.img`
  display: flex;
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

export const SectionItemText = styled.p`
  margin-top: 15px;
  color: ${Colors.white};
  font-size: 22px;
  text-align: center;
`;
