import React from 'react';

import CardPlanUser from '../../../../components/Cards/CardPlanUser';

import {
  FreePlan,
  PremiumPlan,
} from '../../PlanDetails';

import {
  PremiumPlanPrice,
} from '../../PlanPrices';

import {
  PricesUserGridContainer,
  SectionPlanUserTitle,
  PricesUserGridWrapper,
} from './styled';



const SectionPlanUser: React.FC = () => {
  return (

    <PricesUserGridContainer>

      <SectionPlanUserTitle>
        Planos Usuários
      </SectionPlanUserTitle>


      <PricesUserGridWrapper>

        <CardPlanUser
          accent
          name={'Premium'}
          description={'Garanta a experiência completa de um usuário Premium no Secretio.'}
          data={PremiumPlan}
          mensal={PremiumPlanPrice.mensal}
          semestral={PremiumPlanPrice.semestral}
          anual={PremiumPlanPrice.anual}
        />



        <CardPlanUser
          name={'Free'}
          description={'Tenha acesso a conteúdos exclusivos dos(as) acompanhantes.'}
          data={FreePlan}
        />

      </PricesUserGridWrapper>
    </PricesUserGridContainer>

  );
};



export default SectionPlanUser;
