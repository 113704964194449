import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import CardTip from '../../../components/Cards/CardTip';

import {
  Screen,
} from '../../../components/Composh/web';

import {
  TipsHeaderContainer,
  TipsHeaderBackground,
  TipsHeaderContent,
  TipsHeaderInfoView,
  TipsHeaderTitle,
  TipsHeaderText,
} from './styled';



const TipsPartnerScreen: React.FC = () => {
  //   RESPEITO MÚTUO
  // Esse negócio às vezes pode ser difícil. Nunca se esqueça que você está lidando com outro ser humano. Sempre dê o respeito que espera receber.

  // SOBRE O SEU LOCAL
  // Às vezes não é boa ideia mostrar em público sua localização exata. Por isso deixamos você escolher entre manter o GPS que mostra sua atual localização ou selecionar uma localização-padrão em alguma área próxima.


  // INVISTA NO SEU PERFIL
  // Não fique deletando e refazendo seu perfil o tempo todo só para aparecer na mais recente lista de novos membros! É melhor gastar seu tempo mantendo um ótimo perfil para fazer sucesso duradouro. Crie um texto convidativo e mostre fotos sensuais, depois confira como você ficou na grade. Tente alcançar o nível de "conhecido" e ganhar boas avaliações para ser considerado confiável para novos clientes. Os clientes serios evitam marcar com quem tem perfil muito recente ou vazio. Caso você queira dar um tempo, sugerimos desativar o perfil temporariamente.


  // CONFIRA DE IMEDIATO QUEM SÃO SEUS CLIENTES
  // Clientes que entram em contato pelo planetromeo.com terão um perfil de membro. Isto significa que você terá a chance de ver com quem está falando de imediato. Além disso, confira se ele é "conhecido" ou se o perfil é "novo".

  // INVISTA EM SEUS CLIENTES
  // Conseguir novos clientes é sempre importante. Mas você também deve manter contato com seus clientes atuais e cultivá-los. Um leal contingente de fãs é a melhor maneira de ter um negócio estável. Faça login regularmente para conferir as mensagens e respondê-las sem demora.

  // CONECTE-SE COM OUTROS HUNQZ
  // Procure outros HUNQZ por perto que possam ser seus parceiros. Serviços combinados ganham mais clientes. Adicione-os como seus amigos.


  // IMPULSIONE O SEU PERFIL COM PLUS
  // HUNQZ.com é de uso gratuito. Invista em exposição extra fazendo uma assinatura PLUS para impulsionar seu trabalho.

  // ESPECIAL PARA VIAJANTES
  // Você viaja e quer oferecer seus serviços em diferentes cidades? Então deve considerar seriamente usar nosso planejador TRAVEL exclusivo para assinantes PLUS HUNQZ. Você pode criar planos de viagem e ficar em destaque em várias cidades ao mesmo tempo.

  // RESPEITE A LEI
  // HUNQZ.com é uma plataforma mundial de anúncios variados de seviços de acompanhantes para homens. Desde uma experiência do tipo namorado a uma companhia noturna, massagens e serviços de escorte. Para sua própria segurança, não deixe de certificar se os serviços que você oferece são permitidos pelas leis do país.

  // NO TRABALHO...
  // O que você faz ou deixa de fazer é decisão particular sua. Leve isso a sério e não abra mão disso, mesmo se no calor do momento você for tentado por outros.

  // Não se sinta forçado a fazer nada que lhe deixe desconfortável.
  // Tenha coragem e retire-se na hora que quiser.
  // Não deixe sua bebida sem supervisão.
  // Se você receber, não deixe nada de valor por perto.
  // Cuide da sua saúde, é a sua vida. Ninguém pode garantir que ele não tem alguma DST.


  function renderTipExample() {
    return (

      <CardTip
      />

    );
  };



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <TipsHeaderContainer>

        <TipsHeaderBackground
          alt={''}
        />

        <TipsHeaderContent>
          <TipsHeaderInfoView>
            <TipsHeaderTitle>
              Dicas para modelos
            </TipsHeaderTitle>

            <TipsHeaderText>
              PARA O MÁXIMO DE $$$
            </TipsHeaderText>
          </TipsHeaderInfoView>
        </TipsHeaderContent>

      </TipsHeaderContainer>



      <div>
        {renderTipExample()}
      </div>

    </Screen>

  );
};



export default TipsPartnerScreen;
