import React,
{
  useState,
} from 'react';

import {
  Colors,
  Images,
} from '../../../../../shared/constants';

import ButtonAction from '../../../../../components/Buttons/ButtonAction';

import {
  IconTypeModele,
  IconTypeUser,
} from '../../../../../components/Icons';

import {
  SectionRegisterContainer,
  SectionRegisterTitle,
  SectionRegisterNavView,
  SectionRegisterTab,
  SectionRegisterItemTab,
  SectionRegisterIcon,
  SectionRegisterText,
  SectionRegisterDescContainer,
  SectionRegisterDescContent,
  SectionRegisterProfileView,
  SectionRegisterProfileTitle,
  SectionRegisterProfileItemsView,
  SectionRegisterProfileItemsList,
  SectionRegisterProfileWarningText,
  SectionRegisterButtonView,
  SectionRegisterImageView,
  SectionRegisterImage,
} from './styled';



const SectionRegister: React.FC = () => {
  const [typeUser, setTypeUser] = useState(true);



  return (

    <SectionRegisterContainer>
      <SectionRegisterTitle>
        Cadastre-se grátis como:
      </SectionRegisterTitle>


      <SectionRegisterNavView>
        <SectionRegisterTab>

          <SectionRegisterItemTab
            backgroundColor={typeUser ? Colors.secondary : Colors.transparent}
            onClick={() => {
              setTypeUser(true);
            }}>
            <SectionRegisterIcon>
              <IconTypeUser
                color={'#ffffff'}
              />
            </SectionRegisterIcon>

            <SectionRegisterText>
              Modelo
            </SectionRegisterText>
          </SectionRegisterItemTab>


          <SectionRegisterItemTab
            backgroundColor={!typeUser ? Colors.secondary : Colors.transparent}
            onClick={() => {
              setTypeUser(false);
            }}>
            <SectionRegisterIcon>
              <IconTypeModele
                color={'#ffffff'}
              />
            </SectionRegisterIcon>

            <SectionRegisterText>
              Usuário
            </SectionRegisterText>
          </SectionRegisterItemTab>

        </SectionRegisterTab>
      </SectionRegisterNavView>



      <SectionRegisterDescContainer>

        {typeUser && (
          <SectionRegisterDescContent>

            <SectionRegisterProfileView>
              <SectionRegisterProfileTitle>
                Você é modelo? anuncie grátis no Secretio
              </SectionRegisterProfileTitle>


              <SectionRegisterProfileItemsView>
                {[
                  'Anúncio totalmente gratuíto e com acesso ilimitado.',
                  'Publique fotos, vídeos e stories;',
                  'Informe os serviços prestados, locais de atendimento e outros;',
                  'Receba centenas de contatos de clientes de graça.',
                ].map((item) => (
                  <SectionRegisterProfileItemsList>
                    {item}
                  </SectionRegisterProfileItemsList>
                ))}
              </SectionRegisterProfileItemsView>


              <SectionRegisterProfileWarningText>
                Apenas perfis autênticos. Sujeito à verificação de documentação e mídia de comparação.
              </SectionRegisterProfileWarningText>


              <SectionRegisterButtonView>
                <ButtonAction
                  text={'Seja um modelo Secretio'}
                />
              </SectionRegisterButtonView>
            </SectionRegisterProfileView>


            <SectionRegisterImageView>
              <SectionRegisterImage
                src={Images.deviceExampleModel}
              />
            </SectionRegisterImageView>

          </SectionRegisterDescContent>
        )}



        {!typeUser && (
          <SectionRegisterDescContent>

            <SectionRegisterProfileView>
              <SectionRegisterProfileTitle>
                Tenha acesso à funcionalidades exclusivas Secretio
              </SectionRegisterProfileTitle>


              <SectionRegisterProfileItemsView>
                {[
                  'Favorite os seus perfis prediletos;',
                  'Veja as publicações em primeira mão;',
                  'Seu cadastro seguro, discreto e sigiloso;',
                  'Tenha acesso à novas funcionalidades em primeira mão a centenas de contatos de clientes de graça.',
                ].map((item) => (
                  <SectionRegisterProfileItemsList>
                    {item}
                  </SectionRegisterProfileItemsList>
                ))}
              </SectionRegisterProfileItemsView>


              <SectionRegisterButtonView>
                <ButtonAction
                  text={'Quero user o Secretio!'}
                />
              </SectionRegisterButtonView>
            </SectionRegisterProfileView>


            <SectionRegisterImageView>
              <SectionRegisterImage
                src={Images.deviceExampleUser}
              />
            </SectionRegisterImageView>

          </SectionRegisterDescContent>
        )}

      </SectionRegisterDescContainer>

    </SectionRegisterContainer>

  );
};



export default SectionRegister;
