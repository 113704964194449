export const APP_COLORS = {
  primary:                          '#898b9f',
  secondary:                        '#c2a500',
  tertiary:                         '#FFE358',
  accent:                           '#00FFFF',
  danger:                           '#F51E1D',
  attention:                        '#FF7D35',
  accept:                           '#20DC62',
};


export const APP_DEFAULT = {
  black:                            '#000000',
  white:                            '#FFFFFF',
  grayLight:                        '#b5b5b5',
  gray:                             '#898989',
  grayDark:                         '#666666',
  muted:                            '#9FA5AA',
  shadow:                           '#292929',
  transparent:                      'transparent',
  neutral:                          'rgba(255, 255, 255, 0.65)',
};


export const APP_GENRES = {
  male:                             '#3556f8',
  female:                           '#df2020',
  transMale:                        '#9035f8',
  transFemale:                      '#df07b3',
};


export const APP_STATUS = {
  danger:                           '#ef4e4e',
  warning:                          '#ffc600',
  attentionLight:                   '#ffa729',
  attention:                        '#FF7D35',
  attentionDark:                    '#ff630f',
  accept:                           '#46c700',
  disabled:                         '#656565',
  sketch:                           '#d829ff',
};


export const APP_ITEMS = {
  appBackground:                    '#131619',
};


export const BUTTONS = {
  buttonOk:                         '#11a7a1',
  buttonOkLight:                    '#11a7a1',
  buttonOkDark:                     '#11a7a1',
  buttonOkAccent:                   '#13ece1',
  buttonDisabled:                   '#84878E',
};


export const CARD = {
  cardBackground:                   '#1d2226',
  cardOverlay:                      'rgba(0, 0, 0, 0.5)',
  cardButton:                       '#79bfb8',
};



export const GENRES = {
  Male:                             '#3556f8',
  Female:                           '#df2020',
  TransMale:                        '#9035f8',
  TransFemale:                      '#df07b3',
};



export const INPUTS = {
  inputBackground:                  'rgba(255, 255, 255, 0.1)',
};


export const MENU = {
  menuBackground:                   '#141414',
  menuOverlayBackground:            'rgba(0, 0, 0, 0.75)',
};


export const MODALS = {
  alertOverlay:                     'rgba(0, 0, 0, 0.65)',
};


export const REFRESH = {
  refreshBackground:                '#e6e6ea',
};


export const TEXT = {
  textTitle:                        '#545460',
  textDescription:                  '#8a96a3',

  textSubtitle:                     '#797b82',
  textSubDescription:               '#696E7D',

  textApp:                          '#939393',
  subtextApp:                       'rgba(255, 255, 255, 0.5)',
};


export const TOOLBAR = {
  toolbarBackground:                '#18181a',
  toolbarLine:                      '#303346',
  
  // Profile Colors
  // profileToolbar:                   '#0A0A23',
  // profileColorTop:                  '#131a1f',
  // profileColorBottom:               '#404d55',

  // Navigation Bottom Colors
  // navigationBottomBackground:       '#0A0A14',
  // navigationBottomLine:             '#101827',
};



const Colors = {
  ...APP_COLORS,
  ...APP_DEFAULT,
  ...APP_GENRES,
  ...APP_STATUS,
  ...APP_ITEMS,
  ...BUTTONS,
  ...CARD,
  ...GENRES,
  ...INPUTS,
  ...MENU,
  ...MODALS,
  ...REFRESH,
  ...TEXT,
  ...TOOLBAR,
};



export default Colors;
