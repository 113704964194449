import styled from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



interface IProps {
  height?: number;
  backgroundColor?: string;
}



export const ButtonActionContainer = styled.a`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: ${(props: IProps) => props.height}px;
  min-height: ${(props: IProps) => props.height}px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.primary};
  border-radius: 5px;
`;


export const IconLeftView = styled.p`
  margin-right: 10px;
`;

export const IconRightView = styled.p`
  margin-left: 10px;
`;


export const ButtonActionText = styled.p`
  color: white;
  font-size: 15px;
`;

export const ButtonChildrenAction = styled.div`
  align-items: center;
  justify-content: center;
`;
