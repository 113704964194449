import React from 'react';

// import { Container } from './styles';



const TermUseScreen: React.FC = () => {
  return (

    <body>

      TERMOS DE USO DO HUNQZ.COM (TERMOS PARA ESPECTADORES)
      Atualizado em: 09.05.2018

      Bem-vindo ao HUNQZ!

      O serviço “Hunqz.com” é oferecido através da Internet sob a forma de Software-as-a-Service pela empresa Hunqz BV (De Ruijterkade 7, 1013 AA Amesterdão (KvK 70533520)). O uso do Hunqz.com está sujeito aos termos e condições abaixo. Ao aceitar os termos e condições em sua primeira visita ao site Hunqz.com e / ou qualquer uso do site Hunqz.com, você aceita estes termos e condições. Os desvios destes termos e condições só são possíveis por meio de confirmação por escrito da Hunqz BV.

      1. USO DO SERVIÇO
      O serviço Hunqz.com fornece uma plataforma através do site www.hunqz.com.
      Por meio dessa plataforma, os usuários podem visualizar anúncios de serviços de companhia adulta (paga), modelagem, massagem e vídeo. O uso dos serviços anunciados não é de responsabilidade da Hunqz.com. A Hunqz BV não ajuda ativamente na prestação desses serviços ou outras atividades realizadas por usuários ou anunciantes. O uso dos serviços anunciados pode exigir uma conta separada na Romeo BV, para a qual se aplicam termos e condições separados.
      Você deve proteger o acesso e o uso do seu dispositivo, usado para acessar o serviço, contra terceiros. A Hunqz BV pode assumir que todas as ações realizadas a partir do seu endereço IP e / ou identificadores exclusivos de dispositivos são autorizadas e supervisionadas por você. Isso significa que você é responsável por essas ações.
      A Hunqz.com processa seus dados pessoais dentro do escopo do serviço. Consulte a Declaração de Privacidade da Hunqz BV para mais informações.

      2. TERMOS DE USO
      Não é permitido usar o Hunqz.com para qualquer finalidade que viole a legislação ou regulamentação holandesa ou outra lei aplicável. Isso inclui (entre outros) o armazenamento ou transmissão de dados usando o serviço que é calunioso, difamatório ou racista.
      Mais especificamente, no Hunqz.com é proibido violar a privacidade de qualquer parte, por exemplo, distribuindo seus dados pessoais sem permissão ou necessidade clara ou o assédio repetido de terceiros com comunicações indesejadas; infringir os direitos de propriedade intelectual da Hunqz.com e / ou de terceiros; prejudicar a reputação dos outros; exercer atividades comerciais; e fazer qualquer coisa que viole a lei ou regulamento aplicável.
      Caso a Hunqz BV descubra que você viola qualquer dos itens acima, ou receba uma reclamação alegando o mesmo, então a Hunqz BV poderá intervir para encerrar a violação.
      Se, na opinião da Hunqz BV, o funcionamento contínuo dos sistemas informáticos ou da rede da Hunqz BV ou de terceiros estiver realmente ou sob ameaça de ser danificado ou comprometido, por exemplo através da transmissão excessiva de dados, vazamentos de dados pessoais ou atividade de vírus, A BV pode tomar todas as medidas que julgar razoavelmente necessárias para terminar ou evitar tal dano ou risco.
      A Hunqz BV tem o direito de registrar uma queixa criminal por qualquer crime cometido ou usando o serviço. Além disso, a Hunqz BV tem o direito de fornecer seu endereço IP, identificadores exclusivos de dispositivo e / ou quaisquer outros dados de identificação a terceiros, alegando que você viola seus direitos ou termos e condições, desde que a validade da reclamação seja clara, não outra maneira de obter esta informação existe e o terceiro tem um interesse evidente em obter esta informação.
      A Hunqz BV pode recuperar de você todos os danos que sofrer como resultado de sua violação destes termos de uso. Você concorda e isenta a Hunqz BV de todas as reclamações de terceiros decorrentes de sua violação destes termos de uso.

      3. DISPONIBILIDADE E MANUTENÇÃO
      A Hunqz BV não faz promessas sobre a disponibilidade do serviço.
      A Hunqz BV mantém ativamente o serviço. A manutenção pode ocorrer a qualquer momento, mesmo que isso possa afetar negativamente a disponibilidade do serviço. A manutenção é anunciada com antecedência, sempre que possível.
      A Hunqz BV pode, de tempos em tempos, adaptar o serviço. Seus comentários e sugestões são bem-vindos, mas, em última análise, a Hunqz BV decide quais adaptações devem ser realizadas (ou não).

      4. PROPRIEDADE INTELECTUAL
      O serviço Hunqz.com, o software que o acompanha, bem como todas as informações e imagens no site, são propriedade intelectual da Hunqz BV e / ou de seus licenciadores. Nenhum desses itens pode ser copiado ou usado sem permissão prévia por escrito da Hunqz BV, exceto e na medida permitida pela lei obrigatória.
      Se você enviar informações para a Hunqz BV, por exemplo, um relatório de bug ou uma sugestão de melhoria, você concede à Hunqz BV uma licença perpétua e ilimitada para usar essas informações para o serviço.
      A Hunqz BV deve abster-se de acessar os dados armazenados ou transferidos usando o Hunqz.com, a menos que isso seja necessário para uma boa prestação do serviço ou a Hunqz BV for forçada a fazê-lo por lei ou ordem de autoridade competente.

      5. LIMITAÇÃO DE RESPONSABILIDADE
      Exceto em caso de má conduta intencional ou negligência grave, a Hunqz BV não será responsável pelo uso do serviço ou por quaisquer danos relacionados a ele.
      A Hunqz BV em nenhuma circunstância é responsável por danos indiretos, danos consequenciais, perda de lucros, perda de economias ou danos por interrupção de negócios.
      Os danos só podem ser reclamados se comunicados por escrito à Hunqz BV no máximo dois meses após a descoberta.
      Em caso de força maior, a Hunqz BV nunca é obrigada a compensar danos sofridos por você. Força maior inclui, entre outros, interrupções ou indisponibilidade da Internet, infra-estrutura de telecomunicações, interrupções de energia, tumultos, engarrafamentos, greves, interrupções de empresas, interrupções no fornecimento, incêndios e inundações e intervenção do governo.

      6. PRAZO E RESCISÃO
      Este contrato entra em vigor assim que você usa o serviço pela primeira vez e permanece em vigor até ser rescindido.
      Você pode rescindir o contrato a qualquer momento, não visitando mais o site.
      A Hunqz BV tem o direito de rescindir o contrato a qualquer momento, mais especificamente se você tiver violado estes termos de uso.

      7. ALTERAÇÕES NOS TERMOS
      A Hunqz BV pode alterar ou adicionar a estes termos e condições, bem como o próprio serviço a qualquer momento.
      A Hunqz BV deve anunciar, através de alterações ou acréscimos de serviço, pelo menos trinta dias antes de entrar em vigor.
      Se você não quiser aceitar uma alteração ou adição, poderá rescindir o contrato. O uso do Hunqz.com após a data de efeito constituirá sua aceitação dos termos e condições alterados ou adicionados.

      8. DISPOSIÇÕES DIVERSAS
      Exceto na medida em que determinado de outra forma pela lei aplicável obrigatória, a lei holandesa se aplica.
      Exceto na medida em que determinado de outra forma pela lei aplicável obrigatória, todos os litígios emergentes relacionados com a Hunqz.com serão levados ao tribunal holandês competente de Amsterdã.
      No caso de qualquer parte destes termos e condições ser declarada legalmente inválida, isso não afetará a validade de todo o contrato. As partes deverão, em tal caso, concordar com uma ou mais disposições de substituição que se aproximem da intenção original da (s) disposição (ões) inválida (s) dentro dos limites da lei.
      A Hunqz BV tem o direito de transferir seus direitos e obrigações sob este contrato para um terceiro como parte de uma aquisição da Hunqz.com ou das atividades de negócios associadas.



    </body>

  );
};



export default TermUseScreen;
