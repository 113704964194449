import React from 'react';

import {
  Images,
  Sizes,
} from '../../../../../shared/constants';

import Vars from '../../../../../shared/environments/variables';

import ButtonAction from '../../../../../components/Buttons/ButtonAction';

import {
  SectionTitle,
} from '../../../../../styles/styled.layout';

import {
  SectionHeaderView,
  SectionHeaderBackground,
  SectionHeaderInfoContent,
  SectionHeaderInfoView,
  SectionHeaderDescription,
  SectionHeaderButtonView,
} from './styled';



const SectionTopHome: React.FC = () => {
  return (

    <SectionHeaderView>

      <SectionHeaderBackground
        src={Images.backgroundHomeHeader}
      />


      <SectionHeaderInfoContent>

        <SectionHeaderInfoView>
          <SectionTitle>
            Seu segredo está a salvo
          </SectionTitle>

          <SectionHeaderDescription>
            Aqui você pode ser você, sem segredos!
          </SectionHeaderDescription>

          <SectionHeaderButtonView>
            <ButtonAction
              text={'Acesse o Secretio'}
              height={Sizes.landingButtonHeight}
              href={Vars().secretioApp}
            />
          </SectionHeaderButtonView>
        </SectionHeaderInfoView>

      </SectionHeaderInfoContent>

    </SectionHeaderView>

  );
};



export default SectionTopHome;
