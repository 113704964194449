import styled from 'styled-components';

import {
  Colors,
} from '../../../../../shared/constants';

import {
  SectionTitle,
} from './../../../../../styles/styled.layout';



export const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  min-height: 509px;
  background-color: ${Colors.white};
`;

export const FeedbackBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left 20% ;
`;

export const FeedbackContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1440px ;
  margin-right: auto;
  margin-left: auto;
  padding-top: .5rem;
  padding-right: 25px;
  padding-left: 25px;
  padding-bottom: .5rem;
  z-index: 5;
`;

export const FeedbackSectionContent = styled.div`
  flex: 0 0 83.333333%;
  position: relative;
  width: 100%;
  max-width: 83.333333%;
  margin-top: 1.5rem;
  margin-left: 16.666667%;
  margin-bottom: 1.5rem;
  padding-left: 15px;
  padding-right: 15px;
`;

export const FeedbackSectionTitle = styled(SectionTitle)`
  margin-top: 0;
  margin-bottom: 1rem;
  color: ${Colors.black};
  font-size: 2.2rem;
  text-align: left;
`;

export const FeedbackSectionColorTitle = styled.span`
  color: #9d0b98;
`;

export const FeedbackSectionText = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  color: #9e9e9e;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;

export const FeedbackSectionModelsView = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 3rem;
`;


export const FeedbackSectionItemView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 100%;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding-right: 15px;
  padding-left: 15px;
`;

export const FeedbackSectionItemImage = styled.img`
  overflow: hidden;
  width: 120px;
  height: 120px;
  margin-bottom: 0.8rem;
  background-color: #212529;
  object-fit: cover;
  border-radius: 100%;
`;

export const FeedbackSectionItemName = styled.h2`
  margin-top: .5rem;
  margin-bottom: .3rem;
  color: ${Colors.black};
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center;
`;

export const FeedbackSectionItemUserName = styled.h4`
  margin-bottom: .7rem;
  color: ${Colors.black};
  font-size: 0.9rem;
  font-weight: 300;
  text-align: center;
`;

export const FeedbackSectionItemText = styled.p`
  margin-bottom: 1rem;
  color: #323232;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
`;
