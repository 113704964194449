import styled from 'styled-components';
import { Colors } from '../../../shared/constants';



export interface IProps {
  color?: boolean;
}



export const CardPlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 340px;
  background-color: #fff;
  border-radius: 5px;
  border-bottom: 5px solid;
  border-bottom-color: rgb(83, 166, 37);
  box-shadow: 0 0 4px 0 rgba(0,0,0,.25);
`;

export const CardPlanContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`;



export const CardPlanUpperText = styled.div`
  color: #263238;
  box-sizing: border-box;
  border: 0;
  font: inherit;
  margin: 0;
  vertical-align: baseline;
  padding: 20px 20px 0;
`;

export const CardPlanHeaderText = styled.div`
  
`;

export const CardPlanUpperTitle = styled.p`
  color: #263238;
  box-sizing: border-box;
  border: 0;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
`;

export const CardPlanHeaderPlanName = styled.h3`
  box-sizing: border-box;
  border: 0;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  font-weight: 600;
  color: rgb(83, 166, 37);
`;



export const CardPlanDescriptionInfo = styled.div`
  box-sizing: border-box;
  border: 0;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  color: #263238!important;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 16px;
  position: relative;
`;

export const CardPlanDescriptionText = styled.p`
  color: #263238!important;
  box-sizing: border-box;
  border: 0;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
`;

export const CardPlanDescriptionItem = styled.p`
  color: #263238;
  font-size: 12px;
  font-weight: 400;
  justify-content: center;
  line-height: 16px;
`;



export const CardPlanItemContent = styled.div`
  box-sizing: border-box;
  border: 0;
  font: inherit;
  margin: 0;
  vertical-align: baseline;
  color: #5d7a89!important;
  flex-grow: 1;
  padding: 0 20px;
`;

export const CardPlanItemView = styled.div`
  color: #5d7a89!important;
  box-sizing: border-box;
  border: 0;
  font: inherit;
  padding: 0;
  vertical-align: baseline;
  display: flex;
  margin: 16px 0;
`;

export const CardPlanItemIconView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  background-color: ${(props: IProps) => props.color ? '#e25352' : '#263238'};
  border-radius: 50%;
`;

export const CardPlanItemOptionView = styled.div`
  margin-left: 8px;
  margin-top: 2px;
`;

export const CardPlanItemIconTitle = styled.h3`
  color: ${Colors.black};
  font-size: 12px;
  line-height: 16px;
`;

export const CardPlanItemIconText = styled.p`
  margin-top: 2px;
  color: #5d7a89;
  font-size: 12px;
  line-height: 16px;
`;



export const CardPlanPricesContent = styled.div`
  color: #263238;
  box-sizing: border-box;
  border: 0;
  font: inherit;
  margin: 0;
  vertical-align: baseline;
  display: flex;
  justify-content: space-around;
  padding: 16px 20px;
`;



export const CardPlanPlanContractView = styled.div`
  color: #263238;
  box-sizing: border-box;
  border: 0;
  font: inherit;
  padding: 0;
  vertical-align: baseline;
  display: flex;
  justify-content: center;
  margin: 4px 40px 20px;
  opacity: .9;
  transition: .3s;
`;
