import styled from 'styled-components';

import {
  Colors,
} from '../shared/constants';



export const SectionTitle = styled.h3`
  margin-bottom: 32px;
  color: ${Colors.white};
  font-size: 2.875rem;
  text-align: center;
  text-shadow: 1px 1px 0 rgba(0,0,0,.5);
  text-transform: uppercase;
`;
