import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import ButtonAction from '../../Buttons/ButtonAction';

import {
  IconCheck,
} from '../../Icons';

import CardPrice from '../CardPrice';

import {
  CardPlanContainer,
  CardPlanContent,
  CardPlanUpperText,
  CardPlanHeaderText,
  CardPlanUpperTitle,
  CardPlanHeaderPlanName,
  CardPlanDescriptionInfo,
  CardPlanDescriptionText,
  CardPlanDescriptionItem,
  CardPlanItemContent,
  CardPlanItemView,
  CardPlanItemIconView,
  CardPlanItemOptionView,
  CardPlanItemIconTitle,
  CardPlanItemIconText,
  CardPlanPricesContent,
  CardPlanPlanContractView,
} from './styled';



export interface IProps {
  expand?: boolean;
  plan?: string;
  atual?: boolean;
  mensal?: string;
  semestral?: string;
  anual?: string;
  dataArray?: any;
  headerPress?: any;
  buttonPress?: any;
}



const CardPlanModel: React.FC<IProps> = (props) => {
  return (

    <CardPlanContainer>

      <CardPlanContent>

        <CardPlanUpperText>

          <CardPlanHeaderText>
            <CardPlanUpperTitle>
              PLANO
            </CardPlanUpperTitle>

            <CardPlanHeaderPlanName>
              {props.plan}
            </CardPlanHeaderPlanName>
          </CardPlanHeaderText>



          <CardPlanDescriptionInfo>
            <CardPlanDescriptionText>
              Tenha <b>1.5x mais visitas</b> em seu perfil e <b>2x mais contato de clientes</b> que os demais perfis da sua cidade!
            </CardPlanDescriptionText>


            <CardPlanDescriptionItem>
              Dados de média de visitas e de contatos de clientes coletados na plataforma e comparados com perfis sem plano.
            </CardPlanDescriptionItem>
          </CardPlanDescriptionInfo>

        </CardPlanUpperText>



        <CardPlanItemContent>

          {props.dataArray?.map((item: any) => (
            <CardPlanItemView>
              <CardPlanItemIconView>
                <IconCheck
                  color={Colors.white}
                  size={16}
                />
              </CardPlanItemIconView>


              <CardPlanItemOptionView>
                <CardPlanItemIconTitle>
                  {item.title}
                </CardPlanItemIconTitle>

                <CardPlanItemIconText>
                  {item.description}
                </CardPlanItemIconText>
              </CardPlanItemOptionView>
            </CardPlanItemView>
          ))}

        </CardPlanItemContent>



        <CardPlanPricesContent>

          {props.mensal && (
            <CardPrice
              value={props.mensal}
              period={'Mensal'}
            />
          )}


          {props.semestral && (
            <CardPrice
              value={props.semestral}
              period={'Semestral'}
            />
          )}


          {props.anual && (
            <CardPrice
              value={props.anual}
              period={'Anual'}
            />
          )}

        </CardPlanPricesContent>



        <CardPlanPlanContractView>
          <ButtonAction
            backgroundColor={Colors.primary}
            text={'Contrate o Plano'}
          // onPress={props.buttonPress}
          />
          {/* <a href="/login" ><span>Contrate o Plano</span></a> */}
        </CardPlanPlanContractView>
      </CardPlanContent>

    </CardPlanContainer>

  );
};



export default CardPlanModel;
