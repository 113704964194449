import styled from 'styled-components';

import {
  Sizes,
} from '../../../../shared/constants';

import {
  SectionTitle,
} from '../../../../styles/styled.layout';



export const AboutCompanyHQContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 65px;
  padding-bottom: 30px;
  background-color: #0b1c27;
`;

export const AboutCompanyHQView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${Sizes.landingMaxWidth}px;
  margin: 0 auto;
`;

export const AboutCompanyHQInfoView = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50em;
  padding: 32px;
`;

export const AboutCompanyHQTitle = styled(SectionTitle)`
  margin-bottom: 14px;
  font-size: 2.25rem;
  text-align: left;
`;

export const AboutCompanyHQViewStreet = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 1rem;
`;

export const AboutCompanyHQStreet = styled.p`
  margin-top: 5px;
  margin-bottom: 5px;
  color: hsla(0,0%,100%,.5);
  font-size: 1rem;
`;

export const AboutCompanyHQViewNumber = styled.div`
`;
