import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../../shared/constants';



export const SecurityContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  height: 220px;
  min-height: 220px;
  background-color: ${Colors.black};
`;

export const SecurityContainerView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${Sizes.landingMaxWidth}px;
  height: 100%;
`;

export const SecurityTitle = styled.h2`
  width: 412px;
  color: ${Colors.white};
  font-size: 28px;
  text-align: left;
  line-height: 48px;
`;

export const SecurityItemsContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SecurityItemTitle = styled.p`
  margin-bottom: 1.75rem;
  color: ${Colors.white};
  font-size: 22px;
  text-align: center;
`;

export const SecurityItemInfoContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 680px;
  gap: 80px;
`;

export const SecurityItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 14px;
`;

export const SecurityInfoView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SecurityInfoText = styled.p`
  color: ${Colors.white};
  font-size: 18px;
  line-height: 1.5rem;
  text-align: left;
`;
