// User Plans

export const PremiumPlanPrice = {
  mensal:                           'R$ 549,90',
  semestral:                        'R$ 1.209,90',
  anual:                            'R$ 2.499,90',
};



export const FreePlanPrice = {
  mensal:                           'Grátis',
};



// Modele Plans

export const GoldPlanPrice = {
  mensal:                           'R$ 329,90',
  semestral:                        'R$ 1.299,90',
  anual:                            'R$ 2.099,90',
};



export const SilverPlanPrice = {
  mensal:                           'R$ 149,90',
  semestral:                        'R$ 629,90',
  anual:                            'R$ 1.699,90',
};



export const BronzePlanPrice = {
  mensal:                           'R$ 79,90',
  semestral:                        'R$ 329,90',
  anual:                            'R$ 599,90',
};
