import React from 'react';

import {
  Images,
} from '../../../../shared/constants';

import {
  SectionTitle,
} from '../../../../styles/styled.layout';

import {
  AboutCompanyMapView,
  AboutCompanyMapModule,
  AboutCompanyMapBookNow,
  AboutCompanyMapText,
  AboutCompanyMapImageContent,
  AboutCompanyMapImageView,
  AboutCompanyMap,
} from './styled';



const SectionMap: React.FC = () => {
  return (

    <AboutCompanyMapView>

      <AboutCompanyMapModule>
        <AboutCompanyMapBookNow>
          <SectionTitle>
            Internacionais
          </SectionTitle>

          <AboutCompanyMapText>
            Escolha entre uma variedade de modelos dentro do Secretio em todo o mundo!
          </AboutCompanyMapText>
        </AboutCompanyMapBookNow>
      </AboutCompanyMapModule>


      <AboutCompanyMapImageContent>
        <AboutCompanyMapImageView>
          <AboutCompanyMap
            alt={'Map'}
            src={Images.mapLocalSecretio}
          />
        </AboutCompanyMapImageView>
      </AboutCompanyMapImageContent>

    </AboutCompanyMapView>

  );
};



export default SectionMap;
