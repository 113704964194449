import React from 'react';

import {
  Colors,
} from '../../../shared/constants';

import ButtonAction from '../../Buttons/ButtonAction';

import {
  IconCheck,
} from '../../Icons';

import CardPrice from '../CardPrice';

import {
  PricesUserPlanViewFree,
  PricesUserPlanTitleView,
  PricesUserPlanTitle,
  PricesUserPlanDescription,
  PricesUserPlanBenefitsView,
  PricesUserPlanBenefitsList,
  PricesUserPlanBenefitItem,
  PricesUserPlanBenefitIcon,
  PricesUserPlanBenefitText,
  PricesUserPlanRecommendedBadge,
  CardPlanUserPriceView,
  CardPlanUserPriceFree,
  CardPlanUserSendView,
} from './styled';



export interface IProps {
  accent?: boolean;
  name?: string;
  description?: string;
  mensal?: string;
  semestral?: string;
  anual?: string;
  data: Array<string>;
}



const CardPlanUser: React.FC<IProps> = (props: IProps) => {
  return (

    <PricesUserPlanViewFree>
      {props.accent && (
        <PricesUserPlanRecommendedBadge>
          {'Recomendado'.toUpperCase()}
        </PricesUserPlanRecommendedBadge>
      )}



      <PricesUserPlanTitleView>
        <PricesUserPlanTitle
          accent={props.accent}>
          {props.name}
        </PricesUserPlanTitle>


        <PricesUserPlanDescription>
          {props.description}
        </PricesUserPlanDescription>
      </PricesUserPlanTitleView>



      <PricesUserPlanBenefitsView>
        <PricesUserPlanBenefitsList>

          {props.data && props.data?.map((description: string) => (
            <PricesUserPlanBenefitItem>
              <PricesUserPlanBenefitIcon
                accent={props.accent}>
                <IconCheck
                  color={Colors.white}
                  size={16}
                />
              </PricesUserPlanBenefitIcon>


              <PricesUserPlanBenefitText>
                {description}
              </PricesUserPlanBenefitText>
            </PricesUserPlanBenefitItem>
          ))}

        </PricesUserPlanBenefitsList>
      </PricesUserPlanBenefitsView>



      <CardPlanUserPriceView>

        {!props.mensal && !props.semestral && !props.anual && (
          <CardPlanUserPriceFree>
            Grátis
          </CardPlanUserPriceFree>
        )}


        {props.mensal && (
          <CardPrice
            value={props.mensal}
            period={'Mensal'}
          />
        )}


        {props.semestral && (
          <CardPrice
            value={props.semestral}
            period={'Semestral'}
          />
        )}


        {props.anual && (
          <CardPrice
            value={props.anual}
            period={'Anual'}
          />
        )}

      </CardPlanUserPriceView>



      <CardPlanUserSendView>
        <ButtonAction
          backgroundColor={props.accent ? '#e25352' : Colors.primary}
          text={!props.mensal && !props.semestral && !props.anual ? 'Fazer cadastro' : 'Assine agora'}
        // onPress={props.buttonPress}
        />
      </CardPlanUserSendView>
    </PricesUserPlanViewFree>

  );
};



export default CardPlanUser;
