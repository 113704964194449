import React from 'react';

import {
  Images,
} from '../../shared/constants';

import Vars from '../../shared/environments/variables';

import NameRoutes from '../../navigation/names';

import {
  HeaderContainer,
  HeaderContent,
  HeaderLogoButton,
  HeaderLogoImage,
  HeaderButtonsView,
  HeaderCountryButton,
  HeaderCountryImage,
  HeaderCountryText,
  HeaderButtonView,
  HeaderButtonText,
} from './styled';



const Header: React.FC = () => {
  return (

    <HeaderContainer>
      <HeaderContent>

        <HeaderLogoButton
          to={NameRoutes.LandingHomeScreen}>
          <HeaderLogoImage
            alt={'Logo'}
            src={Images.logoSecretioFull}
          />
        </HeaderLogoButton>


        <HeaderButtonsView>

          <HeaderCountryButton
            to={NameRoutes.ChooseCountryScreen}>
            <HeaderCountryImage
              src={'https://github.com/Composh/Composh-Countries/blob/main/Flags/flag-brazil.png?raw=true'}
            />

            <HeaderCountryText>
              Brasil
            </HeaderCountryText>
          </HeaderCountryButton>

          <HeaderButtonView
            href={Vars().secretioApp}>
            <HeaderButtonText>
              Acesse o Secretio
            </HeaderButtonText>
          </HeaderButtonView>

        </HeaderButtonsView>

      </HeaderContent>
    </HeaderContainer>

  );
};



export default Header;
