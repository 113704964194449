import React from 'react';

import {
  Colors,
} from '../../shared/constants';

import {
  Content,
  Screen,
} from '../../components/Composh/web';

import FooterApp from '../../components/Footer';
import Header from '../../components/Header';
import TreeScreens from '../../components/TreeScreens';

import SectionPlanModel from './Sections/PlanModel';
import SectionPlanUser from './Sections/PlanUser';



const PricesScreen: React.FC = () => {
  return (

    <Screen
      backgroundColor={Colors.appBackground}>
      <Content>

        <Header />



        <SectionPlanUser />

        <SectionPlanModel />



        <TreeScreens />

        <FooterApp />

      </Content>
    </Screen>

  );
};



export default PricesScreen;
