import React from 'react';

// import { Container } from './styles';



const TermCookiesScreen: React.FC = () => {
  return (

    <h2>
      Política de Cookies
    </h2>

  );
};



export default TermCookiesScreen;
