// Register
import _registerHow from '../assets/videos/register-how.mp4';



export const REGISTER = {
  registerHow:                      _registerHow,
};



const Videos = {
  ...REGISTER,
};



export default Videos;
