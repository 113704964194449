import React from 'react';

import {
  Colors,
  Images,
} from '../../../shared/constants';

import {
  Content,
  Screen,
} from '../../../components/Composh/web';

import FooterApp from '../../../components/Footer';
import Header from '../../../components/Header';
import SectionItems from '../../../components/Sections/SectionItems';
import TreeScreens from '../../../components/TreeScreens';

import SectionBubble from './Sections/SectionBubble';
import SectionRegister from './Sections/SectionRegister';
import SectionSecurity from './Sections/SectionSecurity';
import SectionTools from './Sections/SectionTools';
import SectionTopHome from './Sections/SectionTopHome';



const LandingHomeScreen: React.FC = () => {
  return (

    <Screen
      backgroundColor={Colors.appBackground}>
      <Content>

        <Header />



        <SectionTopHome />

        <SectionItems
          title={'Divirta-se da forma que quiser.'}
          array={[
            {
              image: Images.iconRent,
              title: 'Rent',
            },
            {
              image: Images.iconFaClub,
              title: 'Fã Clube',
            },
            {
              image: Images.iconLive,
              title: 'Shows Ao Vivo',
            },
          ]}
        />

        <SectionTools />

        <SectionBubble />

        <SectionSecurity />

        <SectionRegister />



        <TreeScreens />

        <FooterApp />

      </Content>
    </Screen>

  );
};



export default LandingHomeScreen;
