import React from 'react';



const TermPrivacyScreen: React.FC = () => {
  return (


    <body>

      DECLARAÇÃO DE PRIVACIDADE DO HUNQZ.COM (PARA VISITANTES)
      Atualizado em: 09.05.2018

      Durante o processamento, estamos em conformidade com os requisitos da legislação de proteção de dados aplicável. Isso significa que nós:

      especificar claramente nossos propósitos antes de processarmos dados pessoais, usando esta Declaração de Privacidade;
      limitar nossa coleta de dados pessoais apenas aos dados pessoais necessários para fins legítimos;
      primeiro peça permissão explícita para processar seus dados pessoais nos casos em que sua permissão é necessária;
      tomar medidas de segurança apropriadas para proteger seus dados pessoais e exigimos o mesmo das partes que processam dados pessoais em nosso nome;
      Respeite o seu direito de inspecionar, corrigir ou excluir seus dados pessoais mantidos por nós.
      A Hunqz BV é a parte responsável por todo o processamento de dados. Nesta declaração de privacidade, vamos explicar quais dados pessoais coletamos e para quais finalidades. Recomendamos que você leia com atenção.

      ESTATÍSTICAS E PERFIS
      Mantemos estatísticas sobre o uso do nosso site de perfil e fazemos isso com base no seu consentimento. Essas estatísticas nos ajudam, por exemplo, a mostrar apenas informações relevantes para você. Podemos combinar dados pessoais para conhecer mais sobre você. Nós, naturalmente, respeitaremos a sua privacidade em todos os momentos. Os tipos de informações analíticas que são coletadas incluem dados básicos de conexão, por exemplo, endereço IP, data e hora da conexão e localização (se compartilhada), comportamento do usuário (por exemplo, recursos usados, frequência de uso), informações de estado do dispositivo, modelo do dispositivo e informações do sistema operacional.

      PUBLICAÇÃO
      Nós não publicaremos seus dados pessoais.

      ANÚNCIOS
      Nosso site de perfil mostra anúncios genéricos. Não coletamos dados pessoais para essa finalidade, por isso não podemos saber se você gosta disso ou não.

      FORNECENDO DADOS A TERCEIROS
      Podemos fornecer seus dados para nossos parceiros . Esses parceiros estão envolvidos na execução do contrato. Esses parceiros podem processar dados pessoais fora da UE. No entanto, eles fazem uso das cláusulas do modelo europeu.

      COOKIES
      Nosso site faz uso de cookies. Cookies são pequenos arquivos nos quais podemos armazenar informações, para que você não precise preencher essas informações novamente. Também podemos usá-los para ver se você está nos visitando novamente.

      A primeira vez que visitar o nosso site, mostraremos uma notificação explicando nossos cookies. Se você continuar usando nosso site, presumimos que você não se opõe a esses cookies.

      Você pode desativar o uso de cookies através da configuração do seu navegador, mas algumas partes do nosso site podem não funcionar corretamente como resultado disso.

      Fizemos acordos com outras partes que colocam cookies em nosso site . No entanto, não podemos controlar totalmente o que estão fazendo com seus cookies, portanto, leia também suas declarações de privacidade.

      GOOGLE ANALYTICS
      Usamos o Google Analytics para rastrear visitantes em nosso site e para receber relatórios sobre como os visitantes usam o site. Aceitamos o contrato de processamento de dados do Google. Não permitimos que o Google use as informações obtidas pelo Google Analytics para outros serviços do Google e anonimizamos os endereços IP.

      SEGURANÇA
      Tomamos medidas de segurança para reduzir o uso indevido e o acesso não autorizado a dados pessoais. Por favor, leia nossa política de segurança publicada separadamente para obter mais informações que serão publicadas o mais breve possível.

      ALTERAÇÕES NESTA DECLARAÇÃO DE PRIVACIDADE
      Nós nos reservamos o direito de modificar esta declaração. Recomendamos que você consulte esta declaração regularmente, para que você permaneça informado de quaisquer alterações.

      INSPEÇÃO E MODIFICAÇÃO DE SEUS DADOS
      Você sempre pode entrar em contato conosco se tiver alguma dúvida sobre nossa política de privacidade ou desejar revisar, modificar ou excluir seus dados pessoais.

      Você tem o seguinte direito:

      Ser informado sobre quais dados pessoais nós temos e para que estamos usando;
      Inspeção dos dados pessoais que mantemos de você;
      Tendo dados incorretos corrigidos;
      Solicitar a exclusão de dados pessoais desatualizados;
      Revogue seu consentimento;
      Objeto para determinados usos.
      Tenha em mente que você sempre deixa claro quem você é, para que possamos garantir que não modificamos ou removemos os dados da pessoa errada.

      RECLAMAÇÕES
      Se você acha que não estamos ajudando você da maneira certa, você tem o direito de apresentar uma queixa na autoridade. Para os Países Baixos, esta é a Autoriteit Persoonsgegevens.

      DETALHES DO CONTATO

    </body>

  );
};



export default TermPrivacyScreen;
