import React,
{
  useState,
} from 'react';

import './styled.css';

import { Colors } from '../../shared/constants';
import { Content, Screen } from '../../components/Composh/web';
import FooterApp from '../../components/Footer';
import Header from '../../components/Header';
import TreeScreens from '../../components/TreeScreens';



const ChooseCountryScreen: React.FC = () => {
  return (

    <Screen
      backgroundColor={Colors.appBackground}>
      <Content>

        <Header />


        <section className="country-world-links">
          <img alt="" className="country-linkExtensions" />

          <div className="world-extensions-content">
            <div className="country-item-link">
              <a className="country-location-box" href="//secretio.men">
                <h3 className="country-locations-title">Institutional</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.Men</span></p>
              </a>
            </div>
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.eu">
                <h3 className="country-locations-title">Global</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.Com</span></p>
              </a>
            </div>
          </div>


          <div className="world-extensions-content">
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.es">
                <h3 className="country-locations-title">España</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.es</span></p>
              </a>
            </div>
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.fr">
                <h3 className="country-locations-title">France</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.fr</span></p>
              </a>
            </div>
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.it">
                <h3 className="country-locations-title">Italia</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.it</span></p>
              </a>
            </div>
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.com.mx">
                <h3 className="country-locations-title">México</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.com.mx</span></p>
              </a>
            </div >
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.com.br">
                <h3 className="country-locations-title">Brasil</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.com.br</span></p>
              </a>
            </div >
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.com.au">
                <h3 className="country-locations-title">Australia</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.com.au</span></p>
              </a>
            </div >
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.pe">
                <h3 className="country-locations-title">Perú</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.pe</span></p>
              </a>
            </div >
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.co">
                <h3 className="country-locations-title">Colombia</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.co</span></p>
              </a>
            </div >
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.in">
                <h3 className="country-locations-title">India</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.in</span></p>
              </a>
            </div >
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.cn">
                <h3 className="country-locations-title">中國</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.cn</span></p>
              </a>
            </div >
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.jp">
                <h3 className="country-locations-title">日本国</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.jp</span></p>
              </a>
            </div >
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.kr">
                <h3 className="country-locations-title">대한민국</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.kr</span></p>
              </a>
            </div >
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.at">
                <h3 className="country-locations-title">Österreich</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.at</span></p>
              </a>
            </div >
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.ch">
                <h3 className="country-locations-title">Switzerland</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.ch</span></p>
              </a>
            </div >
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.my">
                <h3 className="country-locations-title">Malaysia</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.my</span></p>
              </a>
            </div>
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.sg">
                <h3 className="country-locations-title">Singapore</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.sg</span></p>
              </a>
            </div>
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.cl">
                <h3 className="country-locations-title">Chile</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.cl</span></p>
              </a>
            </div>
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.cz">
                <h3 className="country-locations-title">Česká republika</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.cz</span></p>
              </a>
            </div>
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.ph">
                <h3 className="country-locations-title">Pilipinas</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.ph</span></p>
              </a>
            </div>
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.se">
                <h3 className="country-locations-title">Sweden</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.se</span></p>
              </a>
            </div>
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.do">
                <h3 className="country-locations-title">Dominicana</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.do</span></p>
              </a>
            </div>
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.ae">
                <h3 className="country-locations-title">الامارات‎‎</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.ae</span></p>
              </a>
            </div>
            <div className="country-item-link"  >
              <a className="country-location-box" href="//secretio.dk">
                <h3 className="country-locations-title">Denmark‎‎</h3>
                <p className="country-location-link">Secretio<span className="country-locations-domain">.dk</span></p>
              </a>
            </div>
          </div>

        </section >



        <TreeScreens />

        <FooterApp />

      </Content>
    </Screen>

  );
};



export default ChooseCountryScreen;

