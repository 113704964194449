import React,
{
  useState,
} from 'react';

import {
  Colors,
} from '../../../../../shared/constants';

import {
  IconArrowLightBack,
  IconArrowLightForward,
  IconCameraSwitch,
  IconLiked,
  IconUser,
  IconUserCard,
} from '../../../../../components/Icons';

import {
  HomeToolsContainer,
  HomeToolsContent,
  HomeToolsInfoContent,
  HomeToolsInfoTitle,
  HomeToolsInfoSpanTitle,
  HomeToolsInfoDescription,
  HomeToolsCardsContent,
  HomeToolsCardsView,
  HomeToolsCardContainer,
  HomeToolsCardSwiper,
  HomeToolsCardWrapper,
  HomeToolsCardFlex,
  HomeToolsCardFrontContent,
  HomeToolsCardFrontIcon,
  HomeToolsCardFrontText,
  HomeToolsCardArrowIcon,
  HomeToolsCardBackContent,
} from './styled';



const SectionTools: React.FC = () => {
  const [card1, setCard1] = useState(false);
  const [card2, setCard2] = useState(false);
  const [card3, setCard3] = useState(false);
  const [card4, setCard4] = useState(false);



  function renderCardFront(icon: any, text: string) {
    return (

      <HomeToolsCardFlex>
        <HomeToolsCardFrontIcon>
          {icon}
        </HomeToolsCardFrontIcon>


        <HomeToolsCardFrontText>
          {text}
        </HomeToolsCardFrontText>
      </HomeToolsCardFlex>

    );
  }


  function renderCardBack(text: string) {
    return (

      <HomeToolsCardFrontText>
        {text}
      </HomeToolsCardFrontText>

    );
  }


  function renderCardTransformOnClick(textFront: any, textBack: any, status: boolean, onPress: any) {
    return (

      <HomeToolsCardContainer
        onClick={onPress}>
        <HomeToolsCardSwiper>
          <HomeToolsCardWrapper>

            <HomeToolsCardFrontContent
              status={status}>

              <HomeToolsCardFlex>
                {textFront}
              </HomeToolsCardFlex>

              <HomeToolsCardArrowIcon>
                <IconArrowLightForward
                  color={Colors.black}
                  size={30}
                />
              </HomeToolsCardArrowIcon>

            </HomeToolsCardFrontContent>



            <HomeToolsCardBackContent
              status={status}>

              <HomeToolsCardFlex>
                {textBack}
              </HomeToolsCardFlex>


              <HomeToolsCardArrowIcon>
                <IconArrowLightBack
                  color={Colors.black}
                  size={30}
                />
              </HomeToolsCardArrowIcon>

            </HomeToolsCardBackContent>

          </HomeToolsCardWrapper>
        </HomeToolsCardSwiper>
      </HomeToolsCardContainer>

    );
  }



  return (

    <HomeToolsContainer>
      <HomeToolsContent>

        <HomeToolsInfoContent>
          <HomeToolsInfoTitle>
            Encontre acompanhantes no
            {' '}
            <HomeToolsInfoSpanTitle>Secretio</HomeToolsInfoSpanTitle>
          </HomeToolsInfoTitle>

          <HomeToolsInfoDescription>
            A melhor plataforma de acompanhantes
          </HomeToolsInfoDescription>
        </HomeToolsInfoContent>



        <HomeToolsCardsContent>
          <HomeToolsCardsView>

            {renderCardTransformOnClick(
              renderCardFront(
                <IconUserCard
                  color={Colors.black}
                  size={40}
                />,
                'Acompanhantes com documentos verificados',
              ),
              renderCardBack('Todo profissional passa por uma verificação de seus documentos'),
              card1,
              () => {
                setCard1(!card1);
              },
            )}


            {renderCardTransformOnClick(
              renderCardFront(
                <IconUser
                  color={Colors.black}
                  size={40}
                />,
                'Verificação facial',
              ),
              renderCardBack('O Rosto precisa ser sempre igual ao do documento registrado.'),
              card2,
              () => {
                setCard2(!card2);
              },
            )}


            {renderCardTransformOnClick(
              renderCardFront(
                <IconCameraSwitch
                  color={Colors.black}
                  size={40}
                />,
                'Mídia de comparação 360º',
              ),
              renderCardBack('Vídeo verificado com corpo e rosto. Precisa ser o mesmo do documento registrado.'),
              card3,
              () => {
                setCard3(!card3);
              },
            )}


            {renderCardTransformOnClick(
              renderCardFront(
                <IconLiked
                  color={Colors.black}
                  size={40}
                />,
                'Mais do Secretio',
              ),
              renderCardBack('Acesse nosso Blog e saiba mais'),
              card4,
              () => {
                setCard4(!card4);
              },
            )}

          </HomeToolsCardsView>
        </HomeToolsCardsContent>

      </HomeToolsContent>
    </HomeToolsContainer>


  );
};



export default SectionTools;
