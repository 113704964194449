import React from 'react';

import {
  SectionTitle,
} from '../../../styles/styled.layout';

import {
  SectionItemsContainer,
  SectionItemsView,
  SectionItemsRowView,
  SectionViewItem,
  SectionItemImage,
  SectionItemText,
} from './styled';



export interface IProps {
  title: string;
  array: Array<any>;
}



const SectionItems: React.FC<IProps> = (props) => {
  return (

    <SectionItemsContainer>
      <SectionItemsView>

        <SectionTitle>
          {props.title}
        </SectionTitle>


        <SectionItemsRowView>
          {props.array.map((item) => (
            <SectionViewItem>
              <SectionItemImage
                src={item.image}
              />

              <SectionItemText>
                {item.title}
              </SectionItemText>
            </SectionViewItem>
          ))}
        </SectionItemsRowView>

      </SectionItemsView>
    </SectionItemsContainer>
  );
};



export default SectionItems;
