import React from 'react';

import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import AboutCompanyScreen from '../../screens/About';
import BlogScreen from '../../screens/Blog';
import ContactScreen from '../../screens/Contact';
import ChooseCountryScreen from '../../screens/Countries';
import Error404Screen from '../../screens/Errors/404';
import HelpScreen from '../../screens/Help/HelpCenter';
import TipsCustomerScreen from '../../screens/Help/Tips/TipsCustomer';
import TipsPartnerScreen from '../../screens/Help/Tips/TipsPartner';
import BeModeleScreen from '../../screens/Landings/BeModele';

import LandingHomeScreen from '../../screens/Landings/Home';

import PricesScreen from '../../screens/Prices';
import TermCookiesScreen from '../../screens/Terms/TermCookies';
import TermPrivacyScreen from '../../screens/Terms/TermPrivacy';
import TermUseScreen from '../../screens/Terms/TermUse';

import NameRoutes from '../names';



function AppRoutes() {
  return (

    <BrowserRouter>
      <Switch>

        <Route exact path={NameRoutes.LandingHomeScreen} component={LandingHomeScreen} />
        <Route exact path={NameRoutes.BeModeleScreen} component={BeModeleScreen} />

        <Route exact path={NameRoutes.PricesScreen} component={PricesScreen} />
        <Route exact path={NameRoutes.AboutCompanyScreen} component={AboutCompanyScreen} />
        <Route exact path={NameRoutes.BlogScreen} component={BlogScreen} />

        <Route exact path={NameRoutes.ChooseCountryScreen} component={ChooseCountryScreen} />

        <Route exact path={NameRoutes.ContactScreen} component={ContactScreen} />
        <Route exact path={NameRoutes.HelpScreen} component={HelpScreen} />
        <Route exact path={NameRoutes.TipsCustomerScreen} component={TipsCustomerScreen} />
        <Route exact path={NameRoutes.TipsPartnerScreen} component={TipsPartnerScreen} />

        <Route exact path={NameRoutes.TermUseScreen} component={TermUseScreen} />
        <Route exact path={NameRoutes.TermPrivacyScreen} component={TermPrivacyScreen} />
        <Route exact path={NameRoutes.TermCookies} component={TermCookiesScreen} />

        <Route exact path={NameRoutes.Error404Screen} component={Error404Screen} />


        <Route>
          <Redirect to={NameRoutes.Error404Screen} />
        </Route>

      </Switch>
    </BrowserRouter>

  );
}



export default AppRoutes;
