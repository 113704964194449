import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';
import { SectionTitle } from '../../../styles/styled.layout';



export const AboutCompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 480px;
  min-height: 480px;
  max-height: 480px;
  background-color: #0e2431;
`;

export const AboutCompanyBackground = styled.img`
  position: relative;
  min-height: 30rem;
  object-fit: cover;
`;

export const AboutCompanyHeroContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: auto;
  max-width: ${Sizes.landingMaxWidth}px;
  width: 100%;
  margin-top: auto;
  margin-bottom: 1rem;
  transform: translate(-50%,-50%);
`;

export const AboutCompanyHeroInfoView = styled.div`
  width: 30rem;
`;

export const AboutCompanyHeroTitle = styled(SectionTitle)`
  margin-bottom: 0;
  text-align: left;
`;

export const AboutCompanyHeroSubTitle = styled.h4`
  margin-top: 32px;
  color: ${Colors.white};
  font-size: 2rem;
  font-weight: 400;
  text-transform: none;
`;

export const AboutCompanyHeroText = styled.p`
  margin-top: 48px;
  color: ${Colors.white};
  font-size: 16px;
  line-height: 1.5;
`;

export const AboutCompanyHeroSubText = styled.p`
  margin-top: 32px;
  color: ${Colors.gray};
  font-size: 12px;
`;
