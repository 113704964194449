import styled from 'styled-components';



export const CardPlanPricesView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-width: 72px;
  color: #263238;
`;

export const CardPlanPricesDiscountView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #263238;
  border-color: rgb(83, 166, 37);
`;

export const CardPlanPricesDiscountOld = styled.p`
  color: #7a5454;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 10px;
  margin-right: 5px;
  text-decoration: line-through;
  text-decoration-color: #546e7a;
  border-color: rgb(83, 166, 37);
`;

export const CardPlanPricesDiscountPercent = styled.p`
  color: #263238;
  border: 1px solid;
  border-radius: 12px;
  font-size: 12px;
  font-size: 10px;
  font-style: italic;
  font-weight: 600;
  line-height: 12px;
  padding: 3px 6px;
  border-color: rgb(83, 166, 37);
`;

export const CardPlanPricesValue = styled.p`
  color: #263238;
  font-weight: 600;
  margin-top: 5px;
  font-size: 14px;
  line-height: 17px;
`;

export const CardPlanPricesPeriod = styled.p`
  color: #263238;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
`;
