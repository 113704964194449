import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../../shared/constants';



export const SectionHeaderView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 550px;
`;

export const SectionHeaderBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;



export const SectionHeaderInfoContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: ${Sizes.landingMaxWidth}px;
  height: 100%;
  z-index: 1;
`;

export const SectionHeaderInfoView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 350px;
`;



export const SectionHeaderDescription = styled.p`
  color: ${Colors.textSubtitle};
  font-size: 22px;
`;


export const SectionHeaderButtonView = styled.div`
  display: flex;
  margin-top: 40px;
  width: 350px;
`;
