import React from 'react';

import {
  Sizes,
} from '../../../../../shared/constants';

import Vars from '../../../../../shared/environments/variables';

import ButtonAction from '../../../../../components/Buttons/ButtonAction';

import {
  BubbleChatContainer,
  BubbleChatContent,
  BubbleInfoContainer,
  BubbleTitle,
  BubbleInfoText,
  BubbleButtonView,
  BubblesCompView,
  BubbleLeftContent,
  BubbleLeftComponent,
  BubbleRightContent,
  BubbleRightComponent,
  BubbleText,
} from './styled';



const SectionBubble: React.FC = () => {
  return (

    <BubbleChatContainer>
      <BubbleChatContent>

        <BubbleInfoContainer>
          <BubbleTitle>
            Encontre-se aqui no Secretio
          </BubbleTitle>


          <BubbleInfoText>
            A melhor maneira de encontrar gente que pensa como a gente. Você se anima a debater tópicos LGBT+ sérios? Curte esportes, látex ou couro? Seja qual for a sua, pode haver um Grupo próximo de você ou online. Encontre caras bem
            do seu tipo - só no ROMEO.
          </BubbleInfoText>


          <BubbleButtonView>
            <ButtonAction
              text={'Acesse o Secretio'}
              height={Sizes.landingButtonHeight}
              href={Vars().secretioApp}
            />
          </BubbleButtonView>
        </BubbleInfoContainer>



        <BubblesCompView>

          <BubbleLeftContent>
            <BubbleLeftComponent>
              <BubbleText>
                Encontros
              </BubbleText>
            </BubbleLeftComponent>
          </BubbleLeftContent>


          <BubbleRightContent>
            <BubbleRightComponent>
              <BubbleText>
                Amigos
              </BubbleText>
            </BubbleRightComponent>
          </BubbleRightContent>


          <BubbleLeftContent>
            <BubbleLeftComponent>
              <BubbleText>
                Amor
              </BubbleText>
            </BubbleLeftComponent>
          </BubbleLeftContent>

        </BubblesCompView>

      </BubbleChatContent>
    </BubbleChatContainer>

  );
};



export default SectionBubble;
