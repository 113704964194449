import React from 'react';

import {
  IconCameraSwitch,
  IconCardRegister,
  IconPhoneFind,
} from '../../../../../components/Icons';

import {
  SecurityContainer,
  SecurityContainerView,
  SecurityTitle,
  SecurityItemsContent,
  SecurityItemTitle,
  SecurityItemInfoContent,
  SecurityItemIcon,
  SecurityInfoView,
  SecurityInfoText,
} from './styled';



const SectionSecurity: React.FC = () => {
  return (

    <SecurityContainer>
      <SecurityContainerView>

        <SecurityTitle>
          Segurança, respeito e compromisso.
        </SecurityTitle>


        <SecurityItemsContent>
          <SecurityItemTitle>
            Profissionais com:
          </SecurityItemTitle>

          <SecurityItemInfoContent>
            <SecurityInfoView>
              <SecurityItemIcon>
                <IconCardRegister
                  size={34}
                />
              </SecurityItemIcon>

              <SecurityInfoText>
                Documentos verificados
              </SecurityInfoText>
            </SecurityInfoView>


            <SecurityInfoView>
              <SecurityItemIcon>
                <IconPhoneFind
                  size={38}
                />
              </SecurityItemIcon>

              <SecurityInfoText>
                Verificação digital
              </SecurityInfoText>
            </SecurityInfoView>


            <SecurityInfoView>
              <SecurityItemIcon>
                <IconCameraSwitch
                  size={34}
                />
              </SecurityItemIcon>

              <SecurityInfoText>
                Midia de comparação 360º
              </SecurityInfoText>
            </SecurityInfoView>
          </SecurityItemInfoContent>
        </SecurityItemsContent>

      </SecurityContainerView>
    </SecurityContainer>

  );
};



export default SectionSecurity;
