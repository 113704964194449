import styled from 'styled-components';

import {
  Link,
} from 'react-router-dom';

import {
  Colors,
  Sizes,
} from '../../shared/constants';



export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 78px;
  min-height: 78px;
  background-color: ${Colors.toolbarBackground};
  z-index: 999;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: ${Sizes.landingMaxWidth}px;
  padding: 0 25px;
`;

export const HeaderLogoButton = styled(Link)`
  display: flex;
  align-items: center;
  height: 31px;
`;

export const HeaderLogoImage = styled.img`
  height: 35px;
  pointer-event: none;
  object-fit: contain;
`;


export const HeaderButtonsView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
`;

export const HeaderCountryButton = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${Sizes.buttonHeight}px;
  gap: 8px;
`;

export const HeaderCountryImage = styled.img`
  width: 50px;
  height: 100%;
  object-fit: contain;
`;

export const HeaderCountryText = styled.p`
  color: #e4e4e4;
  font-size: 15px;
  text-align: left;
`;

export const HeaderButtonView = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: ${Sizes.buttonHeight}px;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px #e4e4e4 solid;
  border-radius: 5px;
  z-index: 50;
`;

export const HeaderButtonText = styled.p`
  color: #e4e4e4;
  font-size: 16px;
`;
