import styled from 'styled-components';



export interface IProps {
  accent?: boolean;
}



export const PricesUserPlanViewFree = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 340px;
  min-height: 450px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
`;

export const PricesUserPlanTitleView = styled.div`
  color: #263238;
  box-sizing: border-box;
  border: 0;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  margin-top: 0;
`;

export const PricesUserPlanTitle = styled.p`
  max-width: 150px;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  color: ${(props: IProps) => props.accent ? '#e25352' : '#263238'};
`;

export const PricesUserPlanDescription = styled.p`
  min-height: 50px;
  margin: 16px 0 0;
  color: #263238;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;



export const PricesUserPlanBenefitsView = styled.div`
  color: #263238;
  box-sizing: border-box;
  border: 0;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  display: grid;
  flex-grow: 1;
  gap: 18px;
  margin-top: 18px;
`;

export const PricesUserPlanBenefitsList = styled.div`
  color: #263238;
  box-sizing: border-box;
  border: 0;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;



export const PricesUserPlanBenefitItem = styled.div`
  color: #263238;
  box-sizing: border-box;
  border: 0;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const PricesUserPlanBenefitIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  background-color: ${(props: IProps) => props.accent ? '#e25352' : '#263238'};
  border-radius: 50%;
`;

export const PricesUserPlanBenefitText = styled.p`
  box-sizing: border-box;
  border: 0;
  font: inherit;
  padding: 0;
  vertical-align: baseline;
  font-family: Montserrat,sans-serif;
  color: #263238;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  margin: 0;
`;


export const PricesUserPlanRecommendedBadge = styled.p`
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 6px 16px;
  background-color: #fae3e3;
  color: #e25352;
  font-size: 10px;
  font-weight: 700;
  border-radius: 100px;
`;



export const CardPlanUserPriceView = styled.div`
    color: #263238;
  box-sizing: border-box;
  border: 0;
  font: inherit;
  margin: 0;
  vertical-align: baseline;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 16px 20px;
`;

export const CardPlanUserPriceFree = styled.p`
`;



export const CardPlanUserSendView = styled.div`
  display: flex;
  justify-content: center;
`;

