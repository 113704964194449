import styled from 'styled-components';



export const TipsHeaderContainer = styled.div`
color: #fff;
-webkit-font-smoothing: antialiased;
text-rendering: optimizeLegibility;
margin: 0;
padding: 0;
font: inherit;
vertical-align: baseline;
background-color: #0e2431;
position: relative;
display: flex;
flex-direction: column;
`;

export const TipsHeaderBackground = styled.img`
color: #fff;
-webkit-font-smoothing: antialiased;
text-rendering: optimizeLegibility;
margin: 0;
padding: 0;
font: inherit;
vertical-align: baseline;
background-position: top;
background-size: cover;
position: relative;
min-height: 30rem;
background-color: darkred;
`;

export const TipsHeaderContent = styled.div`
color: #fff;
-webkit-font-smoothing: antialiased;
text-rendering: optimizeLegibility;
margin: 0;
padding: 0;
font: inherit;
vertical-align: baseline;
bottom: 0;
left: 50%;
max-width: 1280px;
position: absolute;
transform: translateX(-50%);
width: 100%;
`;

export const TipsHeaderInfoView = styled.div`
color: #fff;
-webkit-font-smoothing: antialiased;
text-rendering: optimizeLegibility;
margin: 0;
font: inherit;
vertical-align: baseline;
width: 100%;
padding: 48px;
`;

export const TipsHeaderTitle = styled.h2`
color: #fff;
-webkit-font-smoothing: antialiased;
text-rendering: optimizeLegibility;
margin: 0;
padding: 0;
font: inherit;
vertical-align: baseline;
font-family: Gibson,Helvetica Neue,Helvetica,Arial,sans-serif;
font-weight: 700;
text-transform: uppercase;
font-size: 2.875rem;
`;

export const TipsHeaderText = styled.h4`
color: #fff;
-webkit-font-smoothing: antialiased;
text-rendering: optimizeLegibility;
margin: 0;
padding: 0;
font: inherit;
vertical-align: baseline;
font-family: Gibson,Helvetica Neue,Helvetica,Arial,sans-serif;
font-weight: 700;
text-transform: uppercase;
font-size: 2rem;
padding-top: 8px;
`;
