import React from 'react';

import {
  Images,
} from '../../../../../shared/constants';

import {
  BeModelRegisterStartView,
  BeModelRegisterStartContent,
  BeModelRegisterStartInfo,
  BeModelRegisterStartTitle,
  BeModelRegisterStartSubTitle,
  BeModelRegisterStartDescription,
  BeModelRegisterStartImageView,
  BeModelRegisterStartImage,
} from './styled';



const SectionStart: React.FC = () => {
  return (

    <BeModelRegisterStartView>
      <BeModelRegisterStartContent>

        <BeModelRegisterStartInfo>
          <BeModelRegisterStartTitle>
            O que preciso para começar?
          </BeModelRegisterStartTitle>

          <BeModelRegisterStartSubTitle>
            Internet, celular ou computador e webcam
          </BeModelRegisterStartSubTitle>

          <BeModelRegisterStartDescription>
            Você também precisa ter mais de 18 anos de idade.
          </BeModelRegisterStartDescription>
        </BeModelRegisterStartInfo>


        <BeModelRegisterStartImageView>
          <BeModelRegisterStartImage
            alt=""
            src={Images.registerRate}
          />
        </BeModelRegisterStartImageView>

      </BeModelRegisterStartContent>
    </BeModelRegisterStartView>

  );
};

export default SectionStart;
