import styled,
{
  css,
} from 'styled-components';

import {
  Sizes,
} from '../../../../../shared/constants';

import {
  SectionTitle,
} from './../../../../../styles/styled.layout';



export const BeModelRegisterStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
  padding: 30px 0;
  background-color: #08000f;
`;

export const BeModelRegisterStartView = styled.div`
  ${BeModelRegisterStyle};
  background-color: #08000f;
`;

export const BeModelRegisterStartContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: ${Sizes.landingMaxWidth}px;
  margin: 0 auto;
  justify-content: space-between;
`;

export const BeModelRegisterStartInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const BeModelRegisterStartTitle = styled(SectionTitle)`
  margin-bottom: 10px;
  color: #f10f4b;
`;

export const BeModelRegisterStartSubTitle = styled.h4`
  margin-bottom: 48px;
  color: #a60832;
  font-weight: 700;
  font-size: 28px;
  line-height: 1.25;
`;

export const BeModelRegisterStartDescription = styled.p`
  color: #fff;
  font-weight: 300;
  font-size: 22px;
  line-height: 1.25;
`;


export const BeModelRegisterStartImageView = styled.div`
  width: 300px;
  padding: 25px;
`;

export const BeModelRegisterStartImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
