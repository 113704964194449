import React from 'react';

import { Screen } from '../../components/Composh/web';
import FooterApp from '../../components/Footer';
import TreeScreens from '../../components/TreeScreens';

import {
  ContactSectionContainer,
  ContactSectionContent,
  ContactSectionFormView,
  ContactSectionInfoImage,
  ContactSectionInfoItemsView,
  ContactSectionInfoView,
} from './styled';



const ContactScreen: React.FC = () => {
  return (

    <Screen>

      <ContactSectionContainer>
        <ContactSectionContent>

          <ContactSectionFormView>
            <h6 className="u-text u-text-default u-text-1">
              Your email address
              Profile name
              Choose a language
              Select a reason for your message
              More information
              Attachments

              Submit
            </h6>
          </ContactSectionFormView>



          <ContactSectionInfoView>
            <ContactSectionInfoImage />

            <ContactSectionInfoItemsView>
              <h3 className="u-text u-text-default u-text-7">Call Us</h3>
            </ContactSectionInfoItemsView>
          </ContactSectionInfoView>

        </ContactSectionContent>
      </ContactSectionContainer>



      <TreeScreens />

      <FooterApp />

    </Screen>

  );
};



export default ContactScreen;
