import React from 'react';

import NameRoutes from '../../navigation/names';

import {
  FooterRightsContainer,
  RightsTextP,
  ListInline,
  ListInlineLI,
  ListButton,
} from './styled';



const FooterApp: React.FC = () => {
  return (

    <FooterRightsContainer>

      <RightsTextP>
        &copy; {new Date().getFullYear()} Secretio. Todos os direitos reservados.
      </RightsTextP>



      <ListInline>

        <ListInlineLI>
          <ListButton
            target={'_blank'}
            to={NameRoutes.HelpScreen}>
            Ajuda
          </ListButton>
        </ListInlineLI>


        <ListInlineLI>
          <ListButton
            target={'_blank'}
            to={NameRoutes.BlogScreen}>
            Blog
          </ListButton>
        </ListInlineLI>


        <ListInlineLI>
          <ListButton
            target={'_blank'}
            to={NameRoutes.ContactScreen}>
            Contato
          </ListButton>
        </ListInlineLI>

      </ListInline>
    </FooterRightsContainer>

  );
};



export default FooterApp;
