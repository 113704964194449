import React from 'react';

import {
  CardTipContainer,
  CardTipTitle,
  CardTipItemsContent,
  CardTipItemText,
} from './styled';



const CardTip: React.FC = () => {
  return (

    <CardTipContainer>

      <CardTipTitle>
        TENHA CAUTELA!
      </CardTipTitle>


      <CardTipItemsContent>
        <ul>
          <li>Especialmente com perfis novos.</li>
          <li>Fique de olho no status `conhecido` que aparece em todo perfil.</li>
          <li>Confira as avaliações.</li>
          <li>Você sempre pode bloquear quem lhe incomoda.</li>
        </ul>
      </CardTipItemsContent>

    </CardTipContainer>

  );
};



export default CardTip;
