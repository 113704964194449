import styled from 'styled-components';

import {
  Link,
} from 'react-router-dom';



export const TreeScreensContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #00838f;  
`;

export const TreeScreensFooterMain = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: center;
  color: #fff;
`;

export const TreeScreensImageView = styled.div`
  display: flex;
  flex-direction: column;
  height: 40px;
  align-items: center;
  margin-bottom: 12px;
`;

export const TreeScreensImage = styled.img`
  height: 100%;
  object-fit: contain;
`;

export const TreeHandwriting = styled.p`
  color: #fff;
  font-size: 18px;
  text-align: center;
`;

export const TreeLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1320px;
  margin-right: auto;
  margin-left: auto;
  padding-left: .75rem;
  padding-right: .75rem;
`;

export const TreeLinksContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.4rem;
  padding-bottom: 1rem;
`;

export const TreeLinksItemView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  max-width: 100%;
  padding-left: 12px;
  padding-right: 12px;
`;

export const TreeLinkViewItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.6rem;
`;

export const TreeLinksTitle = styled.h4`
  color: #fff;
  margin-bottom: 18px;
  font-size: 1em;
  font-weight: bold;
`;

export const TreeLinksButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 25px;
  margin-top: 3px;
  margin-bottom: 3px;
`;

export const TreeLinksText = styled.p`
  color: #fff;
  font-size: 16px;
`;


export const TreeSupportText = styled.p`
  color: #fff;
  font-size: 16px;
`;
