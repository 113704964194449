import React from 'react';

import {
  Images,
} from '../../../shared/constants';

import FooterApp from '../../../components/Footer';
import Header from '../../../components/Header';
import SectionAbout from '../../../components/Sections/SectionAbout';
import SectionItems from '../../../components/Sections/SectionItems';

import TreeScreens from '../../../components/TreeScreens';
import SectionFeedbackModel from './Sections/SectionFeedbackModel';
import SectionInfoHeader from './Sections/SectionInfoHeader';
import SectionStart from './Sections/SectionStart';
import SectionWhy from './Sections/SectionWhy';
// import SectionRegisterStep from './Sections/SectionRegisterStep';

import {
  BeModelContainer,
} from './styled';



const BeModeleScreen: React.FC = () => {
  return (

    <BeModelContainer>


      <Header />

      <SectionInfoHeader />

      {/* <SectionRegisterStep /> */}

      <SectionItems
        title={'Divirta-se Enquanto Faz Dinheiro!'}
        array={[
          {
            image: Images.iconFlexivelHour,
            title: 'Horários de trabalho flexíveis',
          },
          {
            image: Images.iconTalks,
            title: 'Conversas divertidas',
          },
          {
            image: Images.iconMonthlyPayment,
            title: 'Pagamento mensal',
          },
          {
            image: Images.iconSuport24h,
            title: '24/7 suporte ao vivo',
          },
          {
            image: Images.iconLearn,
            title: 'Aprenda a usar o Secretio',
          },
        ]}
      />

      <SectionStart />

      <SectionWhy />

      <SectionAbout
        backgroundImage={Images.backgroundModelsSecurity}
        title={'O Secretio te protege!'}
        text={'A nossa opção de banir estados e países bloqueia os visitantes dos lugares bloqueados*, além da proteção de devolução que mantém os seus ganhos salvos.'}
        subText={'*utilizamos a rede de internet para saber de onde é o usuário, pode não ser preciso a localização.'}
      />

      <SectionFeedbackModel />



      <TreeScreens />

      <FooterApp />

    </BeModelContainer>

  );
};



export default BeModeleScreen;
