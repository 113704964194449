import styled from 'styled-components';



export const CardTipContainer = styled.div`
color: #fff;
-webkit-font-smoothing: antialiased;
text-rendering: optimizeLegibility;
margin: 0;
border: 0;
font: inherit;
vertical-align: baseline;
background-color: hsla(0,0%,100%,.0625);
border-radius: 3px;
display: inline-block;
line-height: 1.5;
width: 100%;
box-sizing: border-box;
margin-top: 16px;
padding: 16px 16px 8px;
`;

export const CardTipTitle = styled.h2`
color: #fff;
box-sizing: border-box;
-webkit-font-smoothing: antialiased;
text-rendering: optimizeLegibility;
margin: 0;
padding: 0;
border: 0;
font: inherit;
vertical-align: baseline;
font-family: Gibson,Helvetica Neue,Helvetica,Arial,sans-serif;
font-size: 1.125rem;
font-weight: 700;
margin-bottom: .5rem;
`;

export const CardTipItemsContent = styled.div`
  
`;

export const CardTipItemText = styled.p`
color: hsla(0,0%,100%,.5);
list-style: none;
list-style-type: circle;
box-sizing: border-box;
-webkit-font-smoothing: antialiased;
text-rendering: optimizeLegibility;
margin: 0;
padding: 0;
border: 0;
font: inherit;
vertical-align: baseline;
`;
