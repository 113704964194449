export const APP_ROUTES = {
  LandingHomeScreen:                '/',
  
  AppDownScreen:                    '/down',
  Error404Screen:                   '/404',

  BeModeleScreen:                   '/model',

  PricesScreen:                     '/prices',
  AboutCompanyScreen:               '/about',
  BlogScreen:                       '/blog',

  ChooseCountryScreen:              '/countries',

  ContactScreen:                    '/contact',
  HelpScreen:                       '/help',
  TipsCustomerScreen:               '/help/tips-customer',
  TipsPartnerScreen:                '/help/tips-partner',

  Error403Page:                     '403',
  Error404Page:                     '404',
  Error500Page:                     '500',

  TermUseScreen:                    '/term-use',
  TermPrivacyScreen:                '/term-policy',
  TermCookies:                      '/term-cookies',
};



const NameRoutes = {
  ...APP_ROUTES,
};



export default NameRoutes;
