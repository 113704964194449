import styled from 'styled-components';

import {
  Sizes,
} from '../../../../shared/constants';



export const SectionHeaderModelView = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 550px;
`;

export const SectionHeaderBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #610000;
  z-index: 0;
`;



export const SectionHeaderInfoContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: ${Sizes.paddingLanding}rem;
  padding-right: ${Sizes.paddingLanding}rem;
  z-index: 1;
`;

export const SectionHeaderInfoView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 350px;
`;


export const SectionHeaderTitle = styled.h2`
  color: #fff;
  margin-bottom: 15px;
  font-size: 68px;
  line-height: 72px;
`;

export const SectionHeaderDescription = styled.p`
  color: #bbb;
  font-size: 15px;
  line-height: 22px;
`;


export const SectionHeaderButtonView = styled.div`
  display: flex;
  margin-top: 40px;
`;
