import React from 'react';

// import { Container } from './styles';



const HelpScreen: React.FC = () => {
  return (

    <h2>
      HelpScreen
    </h2>

  );
};



export default HelpScreen;
