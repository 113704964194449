import React from 'react';

import CardPlanModel from '../../../../components/Cards/CardPlanModel';
import CardPlanUser from '../../../../components/Cards/CardPlanUser';

import {
  ModeleFreePlan,
  ModeleSilverPlan,
  ModeleBronzePlan,
  ModeleGoldPlan,
} from '../../PlanDetails';

import {
  SilverPlanPrice,
  BronzePlanPrice,
  GoldPlanPrice,
} from '../../PlanPrices';

import {
  PricesGridContainer,
  SectionPlanModelTitle,
  PricesGridContent,
} from './styled';



const SectionPlanModel: React.FC = () => {
  return (

    <PricesGridContainer>

      <SectionPlanModelTitle>
        Planos Modelos
      </SectionPlanModelTitle>


      <PricesGridContent>

        <CardPlanModel
          // expand={platinum}
          plan={'Gold'}
          atual={false}
          mensal={GoldPlanPrice.mensal}
          semestral={GoldPlanPrice.semestral}
          anual={GoldPlanPrice.anual}
          dataArray={ModeleGoldPlan}
        // headerPress={() => {
        //   setPlatinum(!platinum);
        // }}
        />


        <CardPlanModel
          // expand={gold}
          plan={'Silver'}
          atual={false}
          mensal={SilverPlanPrice.mensal}
          semestral={SilverPlanPrice.semestral}
          anual={SilverPlanPrice.anual}
          dataArray={ModeleSilverPlan}
        // headerPress={() => {
        //   setGold(!gold);
        // }}
        />


        <CardPlanModel
          // expand={light}
          plan={'Bronze'}
          atual={true}
          mensal={BronzePlanPrice.mensal}
          semestral={BronzePlanPrice.semestral}
          anual={BronzePlanPrice.anual}
          dataArray={ModeleBronzePlan}
        // headerPress={() => {
        //   setLight(!light);
        // }}
        />



        <CardPlanUser
          name={'Free'}
          description={'Seja um modelo gratuitamente.'}
          data={ModeleFreePlan}
        />

      </PricesGridContent>

    </PricesGridContainer>

  );
};



export default SectionPlanModel;
