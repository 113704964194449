import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../../shared/constants';

import {
  SectionTitle,
} from '../../../../../styles/styled.layout';



export const BubbleChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;
  min-height: 500px;
  background-color: #00415b;
`;

export const BubbleChatContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${Sizes.landingMaxWidth}px;
  height: 100%;
  margin-bottom: 1rem;
`;

export const BubbleInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 550px;
`;

export const BubbleTitle = styled(SectionTitle)`
  text-align: left;
`;

export const BubbleInfoText = styled.p`
  color: ${Colors.textSubtitle};
  font-size: 22px;
`;


export const BubbleButtonView = styled.div`
  display: flex;
  margin-top: 40px;
  width: 350px;
`;



export const BubblesCompView = styled.div`
  flex-grow: 1;
  padding: 2rem 0px;
  position: relative;
  max-width: 400px;
`;


export const BubbleStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-width: 11em;
  margin: 1em;
  padding: 1rem 2rem;
  transform: translateY(-1em);
`;

export const BubbleBeforeStyle = css`
  content: "";
  position: absolute;
  bottom: 0px;
  width: 0px;
  height: 0px;
  border-style: solid;
`;


export const BubbleLeftContent = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const BubbleLeftComponent = styled.div`
  ${BubbleStyle};
  background-color: rgb(187, 0, 70);
  border-radius: 0.5em 0.5em 0px;

  :before {
    ${BubbleBeforeStyle};
    border-width: 0px 1em 1em 0px;
    border-color: transparent transparent rgb(187, 0, 70);
    right: -1em;
  }
`;


export const BubbleRightContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BubbleRightComponent = styled.div`
  ${BubbleStyle};
  background: rgb(108, 175, 0);
  border-radius: 0.5em 0.5em 0.5em 0px;

  :before {
    ${BubbleBeforeStyle};
    border-width: 1em 1em 0px 0px;
    border-color: transparent rgb(108, 175, 0) transparent transparent;
    left: -1em;
  }
`;


export const BubbleText = styled.span`
  color: ${Colors.white};
  text-align: center;
  font-size: 1.7em;
  font-weight: 600;
  line-height: 1.2em;
  letter-spacing: 1px;
  text-align: center;
  word-break: break-word;
`;
