import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
} from '../../../../../shared/constants';



export interface IProps {
  status?: boolean;
}



export const HomeToolsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(255 255 255/1);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 70px;
  padding-bottom: 60px;
`;


export const HomeToolsContent = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1180px;
  gap: 1rem;
  width: 88%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;



export const HomeToolsInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 457px;
  flex: 50%;
`;

export const HomeToolsInfoTitle = styled.h3`
  font-weight: inherit;
  font-size: 52px;
  line-height: 64px;
`;

export const HomeToolsInfoSpanTitle = styled.span`
  color: red;
`;

export const HomeToolsInfoDescription = styled.p`
  margin: 0;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 26px;
`;



export const HomeToolsCardsContent = styled.div`
  flex: 50%;
`;

export const HomeToolsCardsView = styled.div`
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  flex-wrap: nowrap;
  display: grid!important;
  grid-template-columns: repeat(2,minmax(0,255px));
  row-gap: 1rem;
  column-gap: 2rem;
  z-index: 1;
`;



// Card

export const HomeToolsCardContainer = styled.div`
  width: 100%!important;
  max-width: 255px!important;
  cursor: auto;
  flex-shrink: 0;
  height: 100%;
  position: relative;
  transition-property: transform;
`;


export const HomeToolsCardSwiper = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  position: relative;
  z-index: 1;
  touch-action: pan-y;
  perspective: 1200px;
  overflow: visible;
`;

export const HomeToolsCardWrapper = styled.div`
  cursor: auto!important;
  flex-wrap: nowrap;
  box-sizing: content-box;
  display: flex;
  height: 100%;
  position: relative;
  transition-property: transform;
  width: 100%;
  z-index: 1;
  transform: translateZ(0);
  transform-style: preserve-3d;
  transition-duration: 0ms;
`;



export const HomeToolsCardFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;



export const HomeToolsCardStyle = css`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: 255px;
  height: 135px;
  padding-top: 5px;
  padding-left: 14px;
  padding-right: 28px;
  padding-bottom: 5px;
  background-color: ${Colors.white};
  border-radius: .75rem;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(198 201 202/1);

  backface-visibility: hidden;
  transition-property: transform;
  transform-style: preserve-3d;
  transition-duration: 300ms;
`;

export const HomeToolsCardFrontContent = styled.div`
  ${HomeToolsCardStyle};

  transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg);
  z-index: 2;

  ${(props: IProps) => props.status && `
    z-index: 1; 
    transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(-180deg);
  `}
`;

export const HomeToolsCardFrontIcon = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HomeToolsCardFrontText = styled.p`
`;

export const HomeToolsCardArrowIcon = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 5px;
  bottom: 7px;
`;



export const HomeToolsCardBackContent = styled.div`
  ${HomeToolsCardStyle};

  transform: translate3d(-255px, 0px, 0px) rotateX(0deg) rotateY(180deg);
  z-index: 1;

  ${(props: IProps) => props.status && `
    z-index: 2; 
    transform: translate3d(-255px, 0px, 0px) rotateX(0deg) rotateY(0deg); 
  `}
`;
