import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../../shared/constants';



export const InfoHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 600px;
  min-height: 600px;
  width: 100%;
`;

export const InfoHeaderBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const InfoHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: ${Sizes.landingMaxWidth}px;
  position: relative;
  height: 100%;
  margin: 0px auto;
  padding-top: 20px;
  padding-bottom: 50px;
`;


export const InfoHeaderRowView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const InfoHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoHeaderLogo = styled.img`
  width: 394px;
  margin-left: -10px;
`;

export const InfoHeaderView = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 460px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const SectionInfoButtonView = styled.div`
  display: flex;
  width: 350px;
`;

export const InfoHeaderText = styled.p`
  margin-top: 5px;
  margin-bottom: 5px;
  color: ${Colors.white};
  font-size: 18px;
  font-weight: 100;
  line-height: 1.42857143;
  text-align: left;
  text-shadow: 1px 1px 1px rgba(0,0,0,.4);
`;



export const InfoHeaderDevicesImage = styled.img`
  width: 550px;
`;

export const InfoHeaderPromotionText = styled.p`
  color: ${Colors.white};
  font-size: 27px;
  font-weight: 500;
  letter-spacing: 3px;
  line-height: 38px;
  text-align: center;
  text-shadow: 2px 2px 2px rgba(0,0,0,.3);
`;
