import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../../../shared/constants';

import {
  SectionTitle,
} from '../../../../../styles/styled.layout';



export interface IProps {
  backgroundColor?: string;
}



export const SectionRegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const SectionRegisterTitle = styled(SectionTitle)`
  margin-top: 58px;
  margin-bottom: 38px;
`;


export const SectionRegisterNavView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-right: 48px;
  padding-left: 48px;
  padding-bottom: 48px;
  box-shadow: 0 8px 20px -17px rgba(255,255,255,0.3);
`;

export const SectionRegisterTab = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 780px;
`;

export const SectionRegisterItemTab = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 10px;
  background-color: ${(props: IProps) => props.backgroundColor};
  border-radius: 5px;
`;

export const SectionRegisterIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

export const SectionRegisterText = styled.p`
  color: ${Colors.white};
  font-size: 16px;
`;


export const SectionRegisterDescContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: ${Sizes.landingMaxWidth}px;
`;

export const SectionRegisterDescContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 540px;
  margin-top: 52px;
  gap: 60px;
`;


export const SectionRegisterProfileView = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-bottom: 30px;
`;

export const SectionRegisterProfileTitle = styled.h3`
  margin-bottom: 30px;
  color: ${Colors.white};
  font-size: 32px;
  line-height: 48px;
`;

export const SectionRegisterProfileItemsView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 30px;
`;

export const SectionRegisterProfileItemsList = styled.p`
  position: relative;
  color: ${Colors.white};
  margin-bottom: 12px;
  padding-left: 16px;
  font-size: 16px;
  line-height: 20px;
  
  :before{
    content: '';
    position: absolute;
    top: 8px;
    left: -2px;
    width: 5px;
    height: 5px;
    background-color: #ff0000;
    border-radius: 9999px;
  }
`;

export const SectionRegisterProfileWarningText = styled.p`
  margin-bottom: 30px;
  color: ${Colors.textSubtitle};
  font-size: 16px;
  line-height: 20px;
`;

export const SectionRegisterButtonView = styled.div`
  display: flex;
  width: 350px;
`;


export const SectionRegisterImageView = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 460px;
  height: 100%;
`;

export const SectionRegisterImage = styled.img`
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain
`;
