import React from 'react';

// import {
//   ActivityIndicator,
// } from 'react-native';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  ButtonActionContainer,
  IconLeftView,
  IconRightView,
  ButtonActionText,
  ButtonChildrenAction,
} from './styled';



export interface IProps {
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  loading?: boolean;

  height?: number;
  backgroundColor?: string;

  text: string;
  iconLeft?: any;
  iconRight?: any;

  href?: any;
  target?: any;

  onPress?: any;
  children?: any;
}



const ButtonAction: React.FC<IProps> = (props) => {
  return (

    <ButtonActionContainer
      type={props.type}
      height={props.height}
      // disabled={props.disabled}
      backgroundColor={props.disabled ? Colors.buttonDisabled : props.backgroundColor}
      href={props.href}
      target={props.href && props.target}
      onClick={props.onPress}>

      {props.iconLeft && !props.loading && (
        <IconLeftView>
          {props.iconLeft}
        </IconLeftView>
      )}



      <ButtonChildrenAction>
        {props.text && !props.loading && (
          <ButtonActionText>
            {props.text}
          </ButtonActionText>
        )}

        {/*
        {props.loading && (
          <ActivityIndicator
            color={'white'}
            size={'small'}
          />
        )}
        */}
      </ButtonChildrenAction>



      {props.iconRight && (
        <IconRightView>
          {props.iconRight}
        </IconRightView>
      )}

    </ButtonActionContainer>

  );
};



ButtonAction.defaultProps = {
  type: 'button',
  disabled: false,
  loading: false,

  height: Sizes.buttonHeight,
};



export default ButtonAction;
