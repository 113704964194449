export const LINKS = {
  secretioRankingApp:               'https://secretio.app/rankings',
};


export const SOCIAL = {
  secretioApp:                      'https://secretio.app/',
  secretioSupportApp:               'https://support.secretio.app/',
  secretioSite:                     'https://site.secretio.app/',
};


export const TERMS = {
  licencesLink:                     'https://site.secretio.app/terms-policy/secretio-license',
  privacityPolicyLink:              'https://site.secretio.app/terms-policy/secretio-privacity',
  termsUseLink:                     'https://site.secretio.app/terms-policy/secretio-use',
};



const Vars = {
  ...LINKS,
  ...SOCIAL,
  ...TERMS,
};



export default Vars;
