import styled from 'styled-components';

import {
  Link,
} from 'react-router-dom';



export const FooterRightsContainer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 68px;
  min-height: 68px;
  background-color: #222222;
  text-align: center;
`;

export const RightsTextP = styled.p`
  margin-bottom: 5px;
  color: rgba(255, 255, 255, 0.3);
  font-size: 12px;
`;

export const ListInline = styled.ul`
  margin-top: -2px;
  padding-left: 0;
  list-style: none;
`;

export const ListInlineLI = styled.li`
  display: inline-block;
  margin-top: 0;
  padding-right: 7px;
  padding-left: 7px;
`;

export const ListButton = styled(Link)`
  color: rgba(255, 255, 255, 0.3);
  font-size: 12px;
  text-decoration: none;
  transition: all 0.35s;

  &:hover {
    transition: all 0.35s;
    color: #009b9b;
  }
`;

