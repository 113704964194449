import styled from 'styled-components';

import {
  Sizes,
} from '../../../../../shared/constants';

import {
  SectionTitle,
} from './../../../../../styles/styled.layout';



export const BeModelSeeHowContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
  padding: 50px 0;
  background-color: #260641;
`;


export const BeModelRegisterStartContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: ${Sizes.landingMaxWidth}px;
  margin: 0 auto;
  justify-content: space-between;
`;

export const BeModelRegisterStartInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const BeModelSeeHowTitle = styled(SectionTitle)`
  margin-bottom: 5px;
  font-size: 48px;
`;

export const BeModelSeeHowName = styled(SectionTitle)`
  margin-bottom: 5px;
  color: #f10f4b;
  font-size: 88px;
`;

export const BeModelSeeHowDescription = styled.p`
  margin-top: 25px;
  color: #b8abc3;
  font-size: 22px;
  font-weight: 300;
  line-height: 1.25;
`;

export const BeModelSeeHowUnderlineDescription = styled.span`
  color: #fff;
  font-weight: 700;
  border-bottom: 4px solid #f10f4b;
`;

export const BeModelSeeHowVideoView = styled.video`
  aspect-ratio: 16/9;
  width: 600px;
  margin-left: 50px;
`;
