import React from 'react';

import {
  AboutCompanyContainer,
  AboutCompanyBackground,
  AboutCompanyHeroContent,
  AboutCompanyHeroInfoView,
  AboutCompanyHeroTitle,
  AboutCompanyHeroSubTitle,
  AboutCompanyHeroText,
  AboutCompanyHeroSubText,
} from './styled';



export interface IProps {
  backgroundImage: string;
  title: string;
  subTitle?: string;
  text?: string;
  subText?: string;
}



const SectionAbout: React.FC<IProps> = (props) => {
  return (

    <AboutCompanyContainer>
      <AboutCompanyBackground
        alt={'Banner'}
        src={props.backgroundImage}
      />


      <AboutCompanyHeroContent>
        <AboutCompanyHeroInfoView>

          <AboutCompanyHeroTitle>
            {props.title}
          </AboutCompanyHeroTitle>

          {props.subTitle && (
            <AboutCompanyHeroSubTitle>
              {props.subTitle}
            </AboutCompanyHeroSubTitle>
          )}

          {props.text && (
            <AboutCompanyHeroText>
              {props.text}
            </AboutCompanyHeroText>
          )}

          {props.subText && (
            <AboutCompanyHeroSubText>
              {props.subText}
            </AboutCompanyHeroSubText>
          )}

        </AboutCompanyHeroInfoView>
      </AboutCompanyHeroContent>
    </AboutCompanyContainer>

  );
};



export default SectionAbout;
