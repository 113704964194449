export const LINKS = {
  secretioRankingApp:               'http://localhost:6969/rankings',
};


export const SOCIAL = {
  secretioApp:                      'http://localhost:6969/',
  secretioSupportApp:               'http://localhost:9670/',
  secretioSite:                     'http://localhost:6971/',
};


export const TERMS = {
  licencesLink:                     'http://localhost:6971/terms-policy/secretio-license',
  privacityPolicyLink:              'http://localhost:6971/terms-policy/secretio-privacity',
  termsUseLink:                     'http://localhost:6971/terms-policy/secretio-use',
};



const Vars = {
  ...LINKS,
  ...SOCIAL,
  ...TERMS,
};



export default Vars;
